import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activePickTime: '',
  newDuration: 0,
};

const timeSlice = createSlice({
  name: 'time',
  initialState,
  reducers: {
    setActivePickTime(state, action) {
      state.activePickTime = action.payload;
    },
    setNewDuration(state, action) {
      state.newDuration = action.payload;
    },
  },
});

export const { setActivePickTime, setNewDuration } = timeSlice.actions;
export default timeSlice.reducer;
