import { Box, Modal, Grid, Typography, TextField, InputAdornment, useMediaQuery, useTheme, Button } from '@mui/material';
import React, { useState, useEffect } from 'react';
import PrimaryButton from '../PrimaryButton';
import { setUser } from '../../store/Profile/UserSlice';
import * as Yup from 'yup';
import Images from '../../utils/Images';
import { ArrowBack } from '@mui/icons-material';
import { API } from '../../utils/Api';
import toast from 'react-hot-toast';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

const AuthModals = ( { openAuth, handleCloseAuth } ) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const matchesMobile = useMediaQuery(theme.breakpoints.up('md'));
    const [open, setopen] = useState(false);
    const [openAccount, setopenAccount] = useState(false);
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [number, setNumber] = useState('');
    const [otp, setOtp] = useState('');
    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [profileImage, setProfileImage] = useState('');
    const [countdown, setCountdown] = useState(59);
    const [errors, setErrors] = useState({});
    const [showOtpField, setShowOtpField] = useState(false);
    const [isGetOtpButtonClicked, setIsGetOtpButtonClicked] = useState(false);
    const [btnDisabledAccount, setBtnDisabledAccount] = useState(false);
    const [changeNumberClicked, setChangeNumberClicked] = useState(false); 
    const [authType, setAuthType] = useState('');
    const [couponCodeModal, setCouponCodeModal] = useState('');
    const [couponPercentageModal, setCouponPercentageModal] = useState('');
    const [couponDiscountPrice, setCouponDiscountPrice] = useState('');
    const [couponModalOpen, setCouponModalOpen] = useState(false);
    const params = useParams();
    const referralId = params['referId'];

    const handleClose = () => {
        setopen(false);
    }
    
    const handleOpenAccount = ()=> {
    setopenAccount(true);
    }

    const handleCloseAccount = () => {
    setopenAccount(false);
    }

    useEffect(() => {
    let interval;
    if (isGetOtpButtonClicked && countdown > 0) {
        interval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
        }, 1000);
    }
    return () => {
        clearInterval(interval);
    };
    }, [countdown, isGetOtpButtonClicked]);

    const schema = Yup.object().shape({
    number: Yup.string()
        .matches(/^[6789][0-9]{9}$/, 'Must be a 10 digit number starting with 6, 7, 8, or 9')
        .required('Mobile Number is required'),
    });

    const validateField = async (field, value) => {
    try {
        await schema.validateAt(field, { number: value });
        setErrors({ ...errors, [field]: '' });
        if (field === 'number' && value.length === 10) {
        }
    } catch (error) {
        if (error instanceof Yup.ValidationError) {
        setErrors({ ...errors, [field]: error.message });
        }
    }
    };

    const handleResendOtp = async () => {
    try {
        const response = await axios.post(`${API.BASE_URL}auth/user/send-otp`, {
        "mobile": number,
        });
        if (response.data.status === true) {
        toast.success('OTP resent successfully');
        }
    } catch (error) {
        console.error('Error:', error.response.data.data.message);
        let errorMessage = error.response.data.data.message;
        toast.error(errorMessage);
    } finally {
    }
    };

    const handleInputChange = (field, value) => {
    if (field === 'number' && !isGetOtpButtonClicked) {
        setNumber(value);
        validateField('number', value);
    } else if (field === 'otp') {
        setOtp(value);
        setErrors({ ...errors, [field]: '' });
    }
    };

    const handleButtonClick = async () => {
        const isValid = await schema.isValid({ number });
        if (!isValid) {
            setErrors({ ...errors, number: 'Mobile Number is required' });
            return;
        }
        setIsGetOtpButtonClicked(true);
        setBtnDisabled(true);
        try {
            const response = await axios.post(`${API.BASE_URL}auth/user/send-otp`, {
                "mobile": number,
            });
            if (response.data.status === true) {
                // console.log('OTP sent successfully:', response.data);
                toast.success('OTP sent successfully!');
                setShowOtpField(true);
                setCountdown(59);
            }
            setAuthType(response.data.data.authType);
            setBtnDisabled(false);
        } catch (error) {
            console.error('Error:', error.response.data.data?.message);
            let errorMessage = error.response.data.data.message;
            toast.error(errorMessage);
            setBtnDisabled(false);
        }
    };

    const handleOtpButtonClick = () => {
        if (authType === 'LOGIN') {
            setBtnDisabled(true);
            axios.post(`${API.BASE_URL}auth/user/verify-otp`, {
                "mobile": number,
                "otp": otp,
                "isSignup": false
            })
            .then(response => {
                // console.log('Login API Response:', response.data);
                localStorage.setItem('userNameHeader', response.data.data.user.name);
                localStorage.setItem('userProfileImage', response.data.data.user.profileImage);
                localStorage.setItem('token', response.data.data.token);
                localStorage.setItem('userDataLogin', JSON.stringify(response.data.data.user));
                dispatch(setUser(response.data.data.user));
                toast.success('Logged in successfully!');
                setBtnDisabled(false);
                // handleClose();
                handleCloseAuth();
                window.location.reload();
            })
            .catch(error => {
                console.error('Error:', error.response.data.data.message);
                let errorMessage = error.response.data.data.message;
                toast.error(errorMessage);
                setBtnDisabled(false);
            });
        }
        else {
            setBtnDisabled(true);
            axios.post(`${API.BASE_URL}auth/user/verify-otp`, {
            "mobile": number,
            "otp": otp,
            "isSignup": true
            })
            .then(response => {
                // console.log('Signin API Response:', response.data);
                toast.success('OTP verified successfully!');
                setBtnDisabled(false);
                // handleClose();
                handleCloseAuth();
                handleOpenAccount();
            })
            .catch(error => {
            console.error('Error:', error.response.data.data.message);
            let errorMessage = error.response.data.data.message;
            toast.error(errorMessage);
            setBtnDisabled(false);
            });
        }
    };

    const handleChangeNumberClick = () => {
    setChangeNumberClicked(true);
    setShowOtpField(false);
    setIsGetOtpButtonClicked(false);
    setCountdown(0);
    };

    const accountSchema = Yup.object().shape({
        name: Yup.string().required('Name is required').matches(/^[A-Za-z][A-Za-z'-]* ?([A-Za-z'-]+ ?)*$/, 'Name must contain only letters, spaces, hyphens, and apostrophes').min(2, 'Name must be at least 2 characters').trim(),
        lastName: Yup.string().optional().matches(/^[A-Za-z][A-Za-z'-]* ?([A-Za-z'-]+ ?)*$/, 'Last Name must contain only letters, spaces, hyphens, and apostrophes').min(2, 'Last Name must be at least 2 characters').trim(),
        // name: Yup.string().required('Name is required').matches(/^[A-Za-z]+$/, 'Name must contain only letters').min(2, 'Name must be at least 2 characters').trim(),
        email: Yup.string().email('Invalid email address').required('Email is required').trim(),
    });

    const validateAccountFields = async (field, value) => {
    try {
        await schema.validateAt(field, { [field]: value });
        setErrors({ ...errors, [field]: '' });
    } catch (error) {
        if (error instanceof Yup.ValidationError) {
        setErrors({ ...errors, [field]: error.message });
        }
    }
    };

    const handleInputAccountChange = (field, value) => {
    if (field === 'name') {
        setName(value);
        validateField('name', value);
    } else if (field === 'lastName') {
        setLastName(value);
        validateField('lastName', value);
    } else if (field === 'email') {
        setEmail(value);
        validateField('email', value);
    }
    };

    const handleAccountButtonClick = async () => {
        setBtnDisabled(true);
        try {
            await accountSchema.validate({ name, email }, { abortEarly: false });
            const requestData = {
                name: name,
                lastName: lastName,
                signupType: "REGULAR",
                mobile: number,
            };
            if (email) {
                requestData.email = email;
            }
            if (referralId) {
                requestData.referringUser = referralId;
            }
            const response = await axios.post(`${API.BASE_URL}auth/user/create-account`,
                requestData
            );
            if (response.data.status === true) {
                // console.log("Account created successfully:", response.data.data.userCouponData.coupon);
                toast.success("Account created successfully!", { autoClose: 3000,});
                handleCloseAccount();
                localStorage.setItem("token", response?.data?.data?.token);
                // localStorage.setItem("couponPercentage", response?.data?.data?.userCouponData?.coupon?.discountPercent);
                // localStorage.setItem('couponDiscountAmount', response?.data?.data?.userCouponData?.coupon?.discountPrice);
                setCouponPercentageModal(response?.data?.data?.userCouponData?.coupon?.discountPercent);
                setCouponDiscountPrice(response?.data?.data?.userCouponData?.coupon?.discountAmount);
                setCouponCodeModal(response?.data?.data?.userCouponData?.coupon?.couponCode);
                // localStorage.setItem("couponCode", response?.data?.data?.userCouponData?.coupon?.couponCode);
                localStorage.setItem('userNameHeader', response?.data?.data?.createdUser?.name);
                localStorage.setItem('userProfileImage', response?.data?.data?.user?.profileImage);
                localStorage.setItem('userDataLogin', JSON.stringify(response?.data?.data?.user));
                window.location.reload();
                setBtnDisabled(false);
                setCouponModalOpen(true);
            }
        } catch (validationError) {
            if (validationError instanceof Yup.ValidationError) {
            const newErrors = {};
            validationError.inner.forEach((error) => {
                newErrors[error.path] = error.message;
            });
            setErrors(newErrors);
            } else {
            console.error("Error:", validationError.response.data.data.message);
            let errorMessage = validationError.response.data.data.message;
            toast.error(errorMessage, {
                autoClose: 3000,
            });
            }
        } finally {
            setBtnDisabled(false);
        }
    };
   
    const handleCouponModalClose = () => {
        setCouponModalOpen(false);
        window.location.reload();
    };

    return (
        <>
            <Modal
                open={openAuth}
                onClose={handleCloseAuth}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
                sx={{ border: '0'}}
                >
                <Box className="authenticationScreen" sx={{outline: 'none'}}>
                    <Grid className='mainBox' item md={3} sx={{ padding: '10px', margin: '0px' }}>
                    <Box sx={{ textAlign: 'center' }}>
                        <img className='imageLogo' src={Images.xpanseSplashLogo} alt="" />
                    </Box>
                    { !isGetOtpButtonClicked && 
                        <Typography className="cup-text" sx={{ fontWeight: '600', fontSize: '25px', margin: '21px 0 31px 0', lineHeight: '1.2' }}>
                        Lets Get You a Cup
                        </Typography>
                    }
                    { isGetOtpButtonClicked &&
                        <Box className="step-away-text" sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                        <ArrowBack sx={{ fontSize: '25px', margin: '0 8px 0 0px' }} onClick={handleChangeNumberClick} />
                        <Typography sx={{ fontWeight: '600', fontSize: '25px' }}>
                            Your cup is one step away
                        </Typography>
                        </Box>
                    }
                    <Box sx={{ textAlign: 'left' }}>
                        <Box className="numberField">
                        <TextField
                            fullWidth
                            id="number"
                            sx={{
                            borderRadius: '8px',
                            backgroundColor: '#fff',
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                borderColor: '#1DA1F2',
                                },
                                '&:hover fieldset': {
                                borderColor: 'cornflowerblue',
                                },
                                '&.Mui-focused fieldset': {
                                borderColor: '#1DA1F2',
                                },
                            },
                            }}
                            disabled={isGetOtpButtonClicked}
                            placeholder="10 digit mobile number"
                            variant="outlined"
                            value={number}
                            onChange={(e) => handleInputChange('number', e.target.value)}
                            onBlur={() => validateField('number', number)}
                            InputLabelProps={{ style: { color: '#2196F3' } }}
                            error={!!errors.number}
                            InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                <Box sx={{ display: 'flex', borderRight: '1px solid #474747', paddingRight: '8px' }}>
                                    <span>+91</span>
                                </Box>
                                </InputAdornment>
                            ),
                            style: { borderColor: '#2196F3' },
                            }}
                        />
                        {errors.number && (
                            <Typography className="errorText" color="error">
                            {errors.number}
                            </Typography>
                        )}
                        </Box>
                        <Box>
                        <TextField
                            fullWidth
                            id="otp"
                            className={`otpField ${showOtpField ? 'showOtpField' : ''}`}
                            sx={{
                            borderRadius: '8px',
                            marginTop: '10px',
                            backgroundColor: '#fff',
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                borderColor: '#1DA1F2',
                                },
                                '&:hover fieldset': {
                                borderColor: 'cornflowerblue',
                                },
                                '&.Mui-focused fieldset': {
                                borderColor: '#1DA1F2',
                                },
                            },
                            }}
                            placeholder="Enter OTP"
                            variant="outlined"
                            value={otp}
                            onChange={(e) => handleInputChange('otp', e.target.value)}
                            onBlur={() => validateField('otp', otp)}
                            inputProps={{ style: { textAlign: 'center' } }}
                            InputLabelProps={{ style: { color: '#2196F3' } }}
                            error={!!errors.otp}
                        />
                        {errors.otp && (
                            <Typography className="errorText" color="error">
                            {errors.otp}
                            </Typography>
                        )}
                        </Box>
                    </Box>
                    <Grid container justifyContent={'center'} style={{ margin: '27px 0 0px 0' }}>
                        <Grid className="buttonContainer" item xs={12} sm={12} md={12} style={{ top: showOtpField ? '0px' : '-60px' }}>
                        <PrimaryButton
                            title={showOtpField ? 'Proceed' : 'Get OTP'}
                            style={{ borderRadius: '30px', fontSize: '58px' }}
                            onClick={() => {
                                if (!showOtpField) {
                                    handleButtonClick();
                                } else {
                                    handleOtpButtonClick();
                                    console.log('Proceed clicked');
                                }
                            }}
                            spinner={true}
                            disabledTitle={'Please Wait'}
                            disabled={btnDisabled}
                        />
                        </Grid>
                    </Grid>
                    { isGetOtpButtonClicked &&
                        <Typography  className="resendOtp-text" style={{ fontSize: '16px', textAlign: 'center', margin: '20px 0 10px 0',  height: showOtpField ? '50px' : '0px'  }}>
                        {countdown <= 0 ? (
                            <span
                                style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                onClick={() => {
                                    handleResendOtp();
                                    setCountdown(59);
                                }}
                            >
                                Resend OTP
                            </span>
                            ) : (
                            <span>
                                Resend OTP in
                            <span
                            style={{
                                textDecoration: 'underline',
                                color: '#fff',
                                cursor: 'pointer',
                                marginLeft: '5px',
                            }}
                            >
                                {countdown < 10 ? `00:0${countdown}` : `00:${countdown}`}
                            </span>
                            </span>
                        )}
                        </Typography>
                    }
                    { !isGetOtpButtonClicked &&
                        <Typography className='termsConditions' style={{ color: '#000', fontSize: '13px', textAlign: 'center' }}>
                        By clicking, I accept the <span onClick={() => window.open('/termsAndConditions', '_blank')} style={{ textDecoration: 'underline', color: '#fff', cursor: 'pointer' }}>terms of service</span> &{' '}
                        <span  onClick={() => window.open('/privacyPolicy', '_blank')} style={{ textDecoration: 'underline', color: '#fff', cursor: 'pointer' }}>privacy policy</span>
                        </Typography>
                    }
                    </Grid>
                </Box>
            </Modal>
            <Modal
                open={openAccount}
                // onClose={handleCloseAccount}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
                sx={{ border: '0'}}
                >
                <Box className="accountDetails" sx={{ outline: 'none', textAlign: 'left', padding: '0px', margin: '0px' }}>
                    <Box sx={{ textAlign: 'center' }}>
                    <img className='imageLogo' src={Images.xpanseSplashLogo} alt="" />
                    </Box>
                    <Grid className="mainBox" item md={3} sx={{ padding: '10px', margin: '0px' }}>
                    <Typography sx={{ fontWeight: '800', fontSize: '25px', margin: '7px 0 11px 0' }}>
                        Help us get to know you
                    </Typography>
                    <Box sx={{ textAlign: 'left' }}>
                        <TextField
                        fullWidth
                        id="name"
                        sx={{
                            borderRadius: '8px',
                            backgroundColor: '#fff',
                            '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: '#1DA1F2',
                            },
                            '&:hover fieldset': {
                                borderColor: 'cornflowerblue',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: '#1DA1F2',
                            },
                            },
                        }}
                        placeholder="Enter Name"
                        variant="outlined"
                        value={name}
                        onChange={(e) => handleInputAccountChange('name', e.target.value)}
                        onBlur={() => validateAccountFields('name', name)}
                        InputLabelProps={{ 
                                shrink: true,
                            }}
                        error={!!errors.name}
                        />
                        {errors.name && (
                        <Typography className="errorText" color="error">
                            {errors.name}
                        </Typography>
                        )}
                        <TextField
                        fullWidth
                        id="lastName"
                        sx={{
                            borderRadius: '8px',
                            backgroundColor: '#fff',
                            marginTop: '20px',
                            '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: '#1DA1F2',
                            },
                            '&:hover fieldset': {
                                borderColor: 'cornflowerblue',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: '#1DA1F2',
                            },
                            },
                        }}
                        placeholder="Last Name (optional)"
                        variant="outlined"
                        value={lastName}
                        onChange={(e) => handleInputAccountChange('lastName', e.target.value)}
                        onBlur={() => validateAccountFields('lastName', lastName)}
                        InputLabelProps={{ 
                            shrink: true,
                        }}
                        error={!!errors.lastName}
                        />
                        {errors.lastName && (
                        <Typography className="errorText" color="error">
                            {errors.lastName}
                        </Typography>
                        )}
                        <TextField
                        fullWidth
                        id="email"
                        sx={{
                            marginTop: '20px',
                            borderRadius: '8px',
                            backgroundColor: '#fff',
                            '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: '#1DA1F2',
                            },
                            '&:hover fieldset': {
                                borderColor: 'cornflowerblue',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: '#1DA1F2',
                            },
                            },
                        }}
                        placeholder="Email"
                        variant="outlined"
                        value={email}
                        onChange={(e) => handleInputAccountChange('email', e.target.value)}
                            InputLabelProps={{ 
                                shrink: true,
                            }}
                        error={!!errors.email}
                        />
                        {errors.email && (
                        <Typography className="errorText" color="error">
                            {errors.email}
                        </Typography>
                        )}
                    </Box>
                    <Grid container justifyContent={'center'} style={{ margin: '21px 0 21px 0' }}>
                        <Grid item xs={12} sm={12} md={12}>
                        <PrimaryButton
                            title={'Continue'}
                            onClick={handleAccountButtonClick}
                            spinner={true}
                            style={{ borderRadius: '30px', fontSize: '18px' }}
                            disabledTitle={'Please Wait'}
                            disabled={btnDisabledAccount}
                        />
                        </Grid>
                    </Grid>
                    </Grid>
                </Box>
            </Modal>
            <Modal
                open={couponModalOpen}
                onClose={handleCouponModalClose}
                sx={{ border: '0'}}
            >
                <Box className="accountDetails" sx={{ outline: 'none', textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '0px', margin: '0px' }}>
                    <Box className="xpanseCouponModal">
                        <img src={Images.welcomeCoupon} alt="welcomeCoupon" style={{}} />
                        {/* {couponPercentageModal ? (
                            <Typography className='percentageeOff'><span>{couponPercentageModal}</span>% OFF</Typography>
                        ) : (
                            <Typography className='percentageeOff'><span>₹{couponDiscountPrice}</span> only</Typography>
                        )} */}
                        <Typography className='percentageeOff'><span>₹59</span> only</Typography>
                        {/* <Typography className='percentageeOff'><span>₹{couponDiscountPrice}</span> only</Typography> */}
                        <Typography className='couponCode'>USE CODE: <span>{couponCodeModal}</span></Typography>
                    </Box>
                    <Button className="couponModalButton" onClick={handleCouponModalClose}>Proceed</Button>
                </Box>
            </Modal> 
        </>
    )
}

export default AuthModals;
