import { Box, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { API } from '../utils/Api';
import Images from '../utils/Images';

const PointsBar = () => {
  const token = localStorage.getItem("token");
  const intervals = [0, 200, 600, 1200];
  const [points, setPOints] = useState([]);
//   const points = 630;
  const totalPoints = 1200;
  // const progress = (points / totalPoints) * 100;
  const progress = Math.min((points / totalPoints) * 100, 100);

  const getMembershipPoints = async () => {
    try {
      const response = await axios.get(`${API.BASE_URL}user/currentUser/wallet-and-level`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setPOints(response?.data?.data?.dbResponse[0]?.earnedPoints);
      // console.log('Successfully fetched membership points data:', response.data.data.dbResponse);
    } catch (error) {
      console.error('Error while fetching membership points data:', error);
    }
  };

  useEffect(() => {  
    getMembershipPoints();
  }, []);
   
  return (
    <div className="progress-bar-container">
      <div className="progress-bar">
        <div className="progress" style={{ width: `${progress}%` }}></div>
        {intervals.map(interval => (
          <React.Fragment key={interval}>
            <div
              className={`marker ${points >= interval ? 'filled' : ''}`}
              style={{ left: `${(interval / totalPoints) * 100 - 0.5}%` }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', position: 'absolute', top: '-25px', transform: 'translateX(-50%)' }}>
                <Typography variant="caption" style={{ color: 'black' }}>
                  {interval}
                </Typography>
                <img
                  src={points >= interval ? Images.xpanseCupPointActive : Images.xpanseCupPointUnActive}
                  alt="cup"
                  style={{ marginLeft: '5px' }}
                />
              </Box>
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default PointsBar;
