import React from 'react';
import { Box, Typography } from '@mui/material';

const ShippingPolicy = () => {
  return (
    <div className='privacyScreen'>
        <Typography className='privacyTitle'>SHIPPING POLICY</Typography>
        <Box className='privacyPolicyContainer'>
            <div>
                <Typography className='lastUpdatedText'>LAST UPDATED: 20-09-2024</Typography>
                <p className='privacyPolicyDesc'>
                    At Xpanse Café, we strive to ensure that your order is delivered quickly and safely. We partner with reliable third-party delivery services to bring your favorite food and beverages directly to your door. Below is our detailed shipping policy:
                </p>
            </div>
            <br/>
            
            <div>
                <Typography className='purposeText'> In-Store Pickup:</Typography>    
                <p className='privacyPolicyDesc'>
                Currently, all orders placed through the Xpanse Café app are available for in-store pickup only. Customers will be notified via the app once their order is ready for collection. Please ensure that you collect your order within the specified time to enjoy your products at their best quality.
                </p>
            </div>

            <div>
                <Typography className='purposeText'> Order Processing:</Typography>    
                <p className='privacyPolicyDesc'>
                    <ul>
                        ●	Upon placing your order, you will receive a notification in the app with the estimated time your order will be ready for pickup.
                        <br/>●	Please ensure you provide accurate contact details during the order process so that we can reach you if necessary.
                        <br/>● To collect your order, please visit the designated Xpanse Café location, show your order confirmation, and our staff will assist you with your purchase.
                    </ul>
                </p>
            </div>
            
            <div>
                <Typography className='purposeText'> Home Delivery (Coming Soon):</Typography>    
                <p className='privacyPolicyDesc'>
                We are working hard to introduce a *Home Delivery* service soon, allowing you to enjoy your favorite Xpanse products at your doorstep. This feature is currently under development and not yet available. Stay tuned for future updates on the availability of home delivery.
                </p>
            </div>

            <div>
                <Typography className='purposeText'> Delivery Areas:</Typography>    
                <p className='privacyPolicyDesc'>
                Xpanse Café offers delivery to select areas based on our operational zones. To check if we deliver to your location, simply enter your delivery address during the checkout process on the Xpanse Café app.
                </p>
            </div>

            <div>
                <Typography className='purposeText'>  Order Processing:</Typography>    
                <p className='privacyPolicyDesc'>
                    <ul>
                        ● Order Confirmation: Once you place your order through our app, you will receive a confirmation email or app notification with your order details and an estimated delivery time.
                        <br/>● Order Preparation: After the 30-second cancellation window, your order is sent to the café for preparation. Once ready, it is handed over to our delivery partner for dispatch.
                    </ul>
                </p>
            </div>

            <div>
                <Typography className='purposeText'> Delivery Times:</Typography>    
                <p className='privacyPolicyDesc'>
                    <ul>
                        ● Delivery times are estimated during checkout. These estimates may vary due to factors like traffic, weather, or high order volumes.
                        <br/>● Peak Hours: Delivery may take longer during peak hours (e.g., lunchtime, dinner) due to higher demand.
                    </ul>
                </p>
            </div>

            <div>
                <Typography className='purposeText'> Delivery Fees:</Typography>    
                <p className='privacyPolicyDesc'>
                    Delivery fees are dynamic and calculated based on your location. The fee will be shown during checkout before you complete payment.
                </p>
            </div>

            <div>
                <Typography className='purposeText'> Recipient&#39;s Responsibility:</Typography>    
                <p className='privacyPolicyDesc'>
                    It is your responsibility to provide accurate and complete delivery details, including the correct address, phone number, and any specific instructions. If your order cannot be delivered due to incorrect information, Xpanse Café will not be responsible for any loss or non-delivery.
                </p>
            </div>

            <div>
                <Typography className='purposeText'> Delivery Times:</Typography>    
                <p className='privacyPolicyDesc'>
                    <ul>
                        ● We use third-party services for delivery. Once your order is handed over to the delivery service, Xpanse Café is no longer liable for any damage or delays that may occur. You should contact the delivery partner directly if there are issues with the delivery.
                        <br/>● Damaged Orders: Any damages caused during transit should be reported to the delivery partner. Unattended Deliveries:
                        <br/>● If the delivery partner is unable to reach you at the time of delivery, they may attempt to leave the order in a safe location or return it to the café. If the order is returned, Xpanse Café is not liable, and no refunds will be provided. You may incur additional charges for re-delivery.
                    </ul>
                </p>
            </div>

            <div>
                <Typography className='purposeText'> Delivery Tracking:</Typography>    
                <p className='privacyPolicyDesc'>
                    You can track your delivery through the Xpanse Café app, where real-time updates are available.
                </p>
            </div>

            <div>
                <Typography className='purposeText'> Force Majeure:</Typography>    
                <p className='privacyPolicyDesc'>
                    Xpanse Café cannot be held responsible for delays due to events beyond our control, such as natural disasters, severe weather, or other force majeure situations.
                </p>
            </div>
            
            <div>
                <Typography className='purposeText'> Disclaimer for Home Delivery: </Typography>    
                <p className='privacyPolicyDesc'>
                    Home Delivery* is a **coming soon** feature and is **not currently active**. For now, orders must be picked up in-store.
                </p>
            </div>

            <div>
                <Typography className='purposeText'> Contact Us:</Typography>    
                <p className='privacyPolicyDesc'>
                    For any delivery-related inquiries or issues, please reach out to our customer service team at contact@xpanscafe.com. We are here to assist you with any concerns regarding your order.
                </p>
            </div>

            <br/>
            <br/>
            <p className='privacyPolicyDesc'>
                Thank you for choosing Xpanse Café! We look forward to serving you.
            </p>
        </Box>
    </div>
  )
}

export default ShippingPolicy;
