import { Box, Typography, Modal, TextField, Grid, InputAdornment, CardMedia, Card, CardContent, Button, Autocomplete, FormControl, RadioGroup, FormControlLabel, Radio, Drawer } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Images from '../utils/Images';
import { SearchBar } from './SearchBar';
import { KeyboardArrowDown, KeyboardArrowRight, SearchSharp } from '@mui/icons-material';
import StoreIcon from '@mui/icons-material/Store';
import { API } from '../utils/Api';
import axios from 'axios';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { setUser } from '../../src/store/Profile/UserSlice';
import * as Yup from 'yup';
import PrimaryButton from './PrimaryButton';
import { ArrowBack } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import ProfileScreen from '../screens/App/ProfileScreen';
import { getPreciseDistance } from 'geolib';
import AdvertiseScreen from '../screens/App/AdvertiseScreen';
import AuthModals from './AuthComponents/AuthModals';

const DesktopHeader = ({ }) => {
  const [store, setStore] = useState([]);
  const [name, setName] = useState('');
  const [profileImage, setProfileImage] = useState('');
  const [openLocation, setopenLocation] = useState(!JSON.parse(localStorage.getItem('selectedStoreData')));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [userData, setUserData] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const initialDrawerState = !localStorage.getItem('selectedOrderType');
  const [drawerOpenForType, setDrawerOpenForType] = useState(initialDrawerState);
  const params = useParams();
  const referralId = params['referId'];

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const toggleDrawerForSelectingType = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    const selectedOrderType = localStorage.getItem('selectedOrderType');
    if (!selectedOrderType && !open) {
      return;
    }
    setDrawerOpenForType(open);
  };

  useEffect(() => {
    setStore(JSON.parse(localStorage.getItem('selectedStoreData')));
  }, [])

  useEffect(() => {  
    const storedUserName = localStorage.getItem('userNameHeader');
    const storedProfileImage = localStorage.getItem('userProfileImage');
    if (storedUserName) {
      setName(storedUserName);
      setProfileImage(storedProfileImage);
    }
  }, []);

  const [isAuthModalOpen, setAuthModalOpen] = useState(false);

  const handleOpenAuth = () => {
    setAuthModalOpen(true);
  }

  const handleCloseAuth = () => {
    setAuthModalOpen(false);
  }

  return (
    <>
    <nav className="navbar">
      <div className="navbar-container">
        <Box className="navbar-brand" sx={{ height: 'auto', display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
          {/* <Box className="" onClick={() => navigate('/')} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
            <img className='navLogo' src={Images.xpanseNavLogo} alt="Logo" />
            <Box className="textBox">
              <Typography className="title">XPANSE</Typography>
              <Typography className="navTag">Freshly Brewed</Typography>
            </Box>
          </Box> */}
          {/* <Box className="" onClick={() => navigate('/')} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}> */}
            <img onClick={() => navigate('/')} className='navLogo' src={Images.xpanseNewNavLogo} alt="Logo" />
          {/* </Box> */}
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box onClick={() => setDrawerOpenForType(true)} className="storeHeader" sx={{ cursor: 'pointer' }}>
            <Box sx={{ display: 'flex', margin: '0px', padding: '0px' }}>
              <StoreIcon className='storeIcon' />
              <Typography className='storeCity'>{store?.name}</Typography>
              <KeyboardArrowRight />
            </Box>
            <Typography className="storeAddress2">{store?.address}</Typography>
          </Box>
          <SearchBar />
        </Box>
        <div onClick={() => {
          const token = localStorage.getItem('token');
            if (token) {
              setDrawerOpen(true);
            } else {
              // handleOpen();
              handleOpenAuth();
            }
          }}>
            <Box className="loginBox" sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
              <Typography className="loginText" sx={{ width: '100%' }}>{name || 'Login / Sign Up'}</Typography>
              <img className="profileImage"
                src={profileImage ? profileImage : userData?.gender === 'MALE' ? Images.maleAvatar : userData?.gender === 'FEMALE' ? Images.femaleAvatar : Images.navProfile} 
                alt='profileIcon' 
                onError={(e) => { e.target.onerror = null; e.target.src = Images.navProfile; }}
              />
            </Box>
        </div>
      </div>
    </nav>
    <AuthModals openAuth={isAuthModalOpen} handleCloseAuth={handleCloseAuth} />
    <Drawer
      anchor="right"
      open={drawerOpen}
      onClose={toggleDrawer(false)}
      classes={{ paper: "drawerPaperForProfile" }}
    >
      <ProfileScreen />
    </Drawer>
    <Drawer
      anchor="left"
      open={drawerOpenForType}
      onClose={toggleDrawerForSelectingType(false)}
      classes={{ paper: "drawerPaperForProfile" }}
    >
      <AdvertiseScreen />
    </Drawer>
    </>
  )
}

export default DesktopHeader;
