import React, { useEffect, useState, useCallback } from 'react';
import TextField from '@mui/material/TextField';
import { Autocomplete } from '@react-google-maps/api';
import { Api, SearchSharp } from '@mui/icons-material';
import { GOOGLE_MAP } from '../utils/Api';
import { Box, InputAdornment } from '@mui/material';

function PlacesAutocomplete({ onCoordinatesChange }) {
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [scriptError, setScriptError] = useState(false);
  const [autocomplete, setAutocomplete] = useState(null);
  const [address, setAddress] = useState("");
  const apiKey = GOOGLE_MAP.GOOGLE_MAP_KEY

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
    script.async = true;
    script.defer = true;
    script.onload = () => setScriptLoaded(true);
    script.onerror = () => {
      setScriptError(true);
      script.remove();
    };
    document.body.appendChild(script);
    return () => {
      if (document.body.contains(script)) {
        document.body.removeChild(script);
      }
    };
  }, [apiKey]);

  const handleOnLoad = useCallback((autoC) => {
    setAutocomplete(autoC);
  }, []);

  const handlePlaceSelect = () => {
    if (autocomplete !== null) {
        const place = autocomplete.getPlace();
        setAddress(place.formatted_address);
      if (place.geometry) {
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        onCoordinatesChange({ lat, lng });
        console.log(`Coordinates: Latitude: ${lat}, Longitude: ${lng}`);
      } else {
        console.log('No geometry data available for this place.');
      }
    } else {
      console.log('Autocomplete is not loaded yet!');
    }
  };

  if (scriptError) return <div>Error loading Google Maps</div>;
  if (!scriptLoaded) return <div>Loading...</div>;

  return (
    <Autocomplete onLoad={handleOnLoad} onPlaceChanged={handlePlaceSelect} options={{ componentRestrictions: { country: 'in' }}}>
      <TextField
        fullWidth
        // label="Enter location"
        value={address}
        onChange={(e) => setAddress(e.target.value)}
        sx={{ backgroundColor: '#fff' }}
        InputLabelProps={{ style: { color: '#2196F3' } }}
        InputProps={{
            startAdornment: (
            <InputAdornment position="start">
                <Box sx={{ display: 'flex', color: '#1DA1F2' }}>
                    <SearchSharp color="#1DA1F2" />
                </Box>
            </InputAdornment>
            ),
            style: { borderColor: '#2196F3' },
        }}
      />
    </Autocomplete>
  );
}

export default PlacesAutocomplete;
