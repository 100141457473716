import React, { useState, useEffect } from "react";
import { TextField, InputAdornment, Box, CircularProgress, Typography, Modal, Button, Autocomplete } from "@mui/material";
import { LoadScript, GoogleMap, MarkerF, InfoWindow, InfoBox } from "@react-google-maps/api";
import { LocationOnOutlined, MyLocation, Place, SearchSharp } from "@mui/icons-material";
import Images from "../../utils/Images";
import axios from "axios";
import { API, GOOGLE_MAP } from "../../utils/Api";
import { Link } from "react-router-dom";
import { getPreciseDistance } from 'geolib';

const LocationScreen = () => {
  const [map, setMap] = useState(null);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [storesData, setStoresData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [cities, setCities] = useState([]);
  const token = localStorage.getItem("token");
  const [selectedCity, setSelectedCity] = useState("");
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [selectedCityData, setSelectedCityData] = useState(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [selectedStore, setSelectedStore] = useState(null);

  const containerStyle = {
    width: "100%",
    height: storesData?.length === 0 ? "90vh" : "60vh",
  };

  const handleCitySelection = (city) => {
    setSelectedCity(city);
  };

  const center = {
    lat: currentLocation?.lat || 0,
    lng: currentLocation?.lng || 0,
  };

  const onLoad = (map) => {
    setMap(map);
    setMapLoaded(true);
  };

  useEffect(() => {
    console.log(token);
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API.BASE_URL}outlet/city/distinct`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data.status === true) {
          console.log("Cities response:", response.data);
          const citiesData = response?.data?.data?.outlets?.map((order) => order.city);
          setCities(citiesData);
          console.log("Cities:", citiesData);
        } else {
          console.error("API request failed");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const fetchLocation = async () => {
    try {
        const position = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        });
        const { latitude, longitude } = position.coords;
        setLatitude(latitude);
        setLongitude(longitude);
        console.log(latitude, longitude);
        const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;
        const response = await fetch(url);
        const data = await response.json();
        if (data.address) {
          // const city = data.address.city;
          const pincode = data?.address?.postcode;
          console.log(pincode);
          if (pincode) {
            try {
              const additionalResponse = await axios.get(
                `${API.BASE_URL}outlet/getOutletCityPincode?pincode=${pincode}`,
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              );
              console.log("City response:", additionalResponse.data);
              if (additionalResponse?.data?.status === false) {
                setModalOpen(true);
              } else {
                setStoresData(additionalResponse?.data.data.totalOutletRecords);
                console.log('Stores data', storesData);
              }
            } catch (error) {
              console.log("Error fetching additional data:", error);
            }
          }
        } else {
          console.error("Geocoding API did not return expected data");
        }
    } catch (error) {
      console.error("Error getting current location:", error);
      if (error.code === error.PERMISSION_DENIED) {
        console.log('Enable location permission');
        const isPermissionGranted = window.confirm("Location permission is required. Do you want to allow it?");
        if (isPermissionGranted) {
          fetchLocation();
        } else {
          window.prompt("Location permission is required to proceed.");
        }
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLocation();
  }, []);
  
  const changeCity = async () => {
    try {
      const additionalResponse = await axios.get(
        `${API.BASE_URL}outlet/getOutletCityPincode?city=${selectedCity}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Selected city Response:", additionalResponse.data);
      if (additionalResponse?.data?.status === false) {
        setModalOpen(true);
      } else {
        const selectedCityData = additionalResponse?.data?.data;
        const selectedStoreData = selectedCityData?.outlets;
        setStoresData(selectedStoreData);
      }
    } catch (error) {
      console.log("Change city error data:", error);
    }
  };

  useEffect(() => {
    const changeCity = async () => {
      try {
        const additionalResponse = await axios.get(
          `${API.BASE_URL}outlet/getOutletCityPincode?city=${selectedCity}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log("Selected city Response:", additionalResponse.data);
        if (additionalResponse?.data?.status === false) {
          setModalOpen(true);
        } else {
          setStoresData(additionalResponse?.data?.data?.totalOutletRecords);
        }
      } catch (error) {
        console.log("Change city error data:", error);
      }
    };
    if (selectedCity) {
      changeCity();
    }
  }, [selectedCity]);

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleStoreClick = (selectedStore) => {
    localStorage.setItem('selectedStoreData', JSON.stringify(selectedStore));
  };

  const handleMarkerClick = (store) => {
    setSelectedStore(store);
  };

  const handleCloseInfoWindow = () => {
    setSelectedStore(null);
  };
  
  const calculateDistance = (store) => {
    const lat = store?.coordinates?.latitude;
    const lon = store?.coordinates?.longitude;
    if (lat == null || lon == null || isNaN(parseFloat(lat)) || isNaN(parseFloat(lon))) {
      return null;
    }
    const distanceInMeters = getPreciseDistance(
      { latitude, longitude },
      { latitude: parseFloat(lat), longitude: parseFloat(lon) }
    );
    if (isNaN(distanceInMeters)) {
      return null;
    }
    if (distanceInMeters < 1000) {
      return `${distanceInMeters} meters`;
    } else {
      const distanceInKilometers = (distanceInMeters / 1000).toFixed(1);
      return `${distanceInKilometers} km`;
    }
  };

  const mapLoaderElement = () => {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          flexDirection: 'column',
          textAlign: 'center',
          backgroundColor: '#eaeaea'
        }}
      >
        <LocationOnOutlined sx={{ fontSize: 50}} />
        <Typography variant="h6">Picking Up <br/>Location</Typography>
      </Box>
    )
  };

  return (
    <LoadScript googleMapsApiKey={GOOGLE_MAP.GOOGLE_MAP_KEY} loadingElement={mapLoaderElement}>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            flexDirection: 'column',
            textAlign: 'center',
            backgroundColor: '#eaeaea'
          }}
        >
          <LocationOnOutlined sx={{ fontSize: 50}} />
          <Typography variant="h6">Picking Up <br/>Location</Typography>
        </Box>
      ) : (
        latitude && longitude && (
          <>
            <Box className="searchCityBox" sx={{ left: "12%" }}>
              <Autocomplete
                fullWidth
                id="search"
                options={cities}
                noOptionsText="No city found"
                sx={{
                  borderRadius: '8px',
                  backgroundColor: '#fff',
                  '& .MuiAutocomplete-clearIndicator': {
                    display: 'none',
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Search for store near you"
                    InputLabelProps={{ style: { color: '#2196F3' } }}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <Box sx={{ display: 'flex', color: '#1DA1F2' }}>
                            <SearchSharp color="#1DA1F2" />
                          </Box>
                        </InputAdornment>
                      ),
                      style: { borderColor: '#2196F3' },
                    }}
                  />
                )}
                onInputChange={handleSearchChange}
                disableClearable
                onChange={(event, value) => {
                  if (value) {
                    setSelectedCity(value);
                    changeCity();
                  }
                }}
              />
            </Box>
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={{ lat: latitude, lng: longitude }}
              zoom={14}
              onLoad={onLoad}
              options={{ mapTypeControl: false, zoomControl: false, fullscreenControl: false, keyboardShortcuts: false }}
            >
              {latitude && longitude && mapLoaded && (
                <MarkerF
                  position={{ lat: latitude, lng: longitude }}
                  title="Your Location"
                />
              )}
              {selectedStore && (
                <InfoWindow
                  position={{
                    lat: parseFloat(selectedStore?.coordinates?.latitude),
                    lng: parseFloat(selectedStore?.coordinates?.longitude),
                  }}
                  onCloseClick={handleCloseInfoWindow}
                >
                  <Box sx={{ backgroundColor: '#fff', textAlign: 'center', padding: '5px 10px 5px 10px' }}>
                    <Typography variant="h6">{selectedStore?.name}</Typography>
                  </Box>
                </InfoWindow>
              )}
              {storesData?.map((store, index) => (
                <MarkerF
                  position={{ lat: parseFloat(store?.coordinates?.latitude), lng: parseFloat(store?.coordinates?.longitude) }}
                  icon={Images.xpanseMapMarker}
                  title={store?.name}
                  onClick={() => handleMarkerClick(store)}
                />
              ))}
            </GoogleMap>
            {storesData?.length === 0 ? (
              null
            ) : (
              <>
              <Box className="locateMeButton" onClick={fetchLocation}> <Place />  Locate Me</Box>
              <div className="stores-container">
                {storesData?.map((store, index) => (
                  <Link style={{ textDecoration: 'none' }} to="/productsListing" key={index} onClick={() => handleStoreClick(store)}>
                    <figure className={index === 0 ? "borderStore" : ""}>
                      <picture>
                        <img src={store?.outletImage} alt={store?.city} />
                      </picture>
                      <figcaption className="storeName">{store?.name}</figcaption>
                      <figcaption className="storeDistance">{calculateDistance(store)}</figcaption>
                      {index === 0 && <Box className="nearestTag"><MyLocation sx={{ paddingRight: '5px'}} /> Nearest</Box>}
                    </figure>
                  </Link>
                ))}
              </div>
              </>
            )}
          </>
        )
      )}
      <Modal open={modalOpen} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "white",
            boxShadow: 0,
            padding: '10px 30px 10px 30px',
            borderRadius: '12px',
            textAlign: 'center'
          }}
        >
          <Typography variant="h6" component="div" gutterBottom>
            No outlets found for your city.
          </Typography>
          <Button onClick={handleCloseModal}>Continue</Button>
        </Box>
      </Modal>
      <style>
        {`
          body, html {
            margin: 0;
            padding: 0;
          }
        `}
      </style>
    </LoadScript>
  );
};

export default LocationScreen;
