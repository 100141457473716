import { createSlice } from '@reduxjs/toolkit';

const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    data: null,
    error: null,
    refreshFlag: false,
  },
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
      state.error = null;
    },
    setError: (state, action) => {
      state.data = null;
      state.error = action.payload;
    },
    setRefreshFlag: (state, action) => {
      state.refreshFlag = !state.refreshFlag;
    },
  },
});

export const { setData, setError, setRefreshFlag } = cartSlice.actions;
export default cartSlice.reducer;