import React, { useEffect, useRef, useState } from 'react';
import Images from '../utils/Images';
import { Box, Typography } from '@mui/material';

function ScrollCouponComponent() {
    const [first, setFirst] = useState(0);
    const scrollViewRef = useRef(null);

    const imagesArr = [
        Images.coupon1,
        Images.coupon2,
        'https://t4.ftcdn.net/jpg/01/16/61/93/360_F_116619399_YA611bKNOW35ffK0OiyuaOcjAgXgKBui.jpg',
        'https://img.freepik.com/free-photo/fresh-coffee-steams-wooden-table-close-up-generative-ai_188544-8923.jpg',
        'https://img.freepik.com/free-photo/fresh-coffee-steams-wooden-table-close-up-generative-ai_188544-8923.jpg',
        'https://img.freepik.com/free-photo/fresh-coffee-steams-wooden-table-close-up-generative-ai_188544-8923.jpg',
        'https://img.freepik.com/free-photo/fresh-coffee-steams-wooden-table-close-up-generative-ai_188544-8923.jpg',
        'https://img.freepik.com/free-photo/fresh-coffee-steams-wooden-table-close-up-generative-ai_188544-8923.jpg',
        'https://img.freepik.com/free-photo/fresh-coffee-steams-wooden-table-close-up-generative-ai_188544-8923.jpg',
        'https://img.freepik.com/free-photo/fresh-coffee-steams-wooden-table-close-up-generative-ai_188544-8923.jpg',
    ];

    const handleScroll = (event) => {
        const { target } = event;
        const { scrollLeft, clientWidth, scrollWidth } = target;

        const visibleWidth = clientWidth;
        const totalWidth = scrollWidth;
        const totalImages = imagesArr.length;
        const imageWidth = 250;

        const visibleImages = Math.floor(visibleWidth / imageWidth);
        const hiddenImages = Math.ceil((totalWidth - scrollLeft - visibleWidth - imageWidth) / imageWidth);

        setFirst(hiddenImages);
    };

    useEffect(() => {
        const scrollTimeout = setTimeout(() => {
            scrollViewRef.current.scrollLeft = 1;
        }, 100); // Adjust the delay as needed (e.g., 100 milliseconds)

        return () => clearTimeout(scrollTimeout);
    }, []);

    return (
        <Box className="scrollCouponComponent" sx={{ position: 'relative' }}>
            <Typography className='scrollTitle' variant='body1'>Coupons Unlocked</Typography>
            <Box
                ref={scrollViewRef}
                sx={{ display: 'flex', overflowX: 'auto', gap: '10px', padding: '10px', backgroundColor: '#ffffff' }}
                onScroll={handleScroll}
                className="scrollCouponContainer"
            >
                {imagesArr.map((img, indx) => (
                    <Box key={indx}>
                        <Box sx={{ borderRadius: '5px', overflow: 'hidden' }}>
                            <img alt={`img${indx}`} src={img} style={{ height: '100px', width: '250px', cursor: 'pointer' }} />
                        </Box>
                    </Box>
                ))}
            </Box>
            {first > 0 && (
                <Box className="couponCount" style={{  }}>
                    <Typography sx={{ color: 'white', fontSize: '30px' }}>+{first}</Typography>
                </Box>
            )}
        </Box>
    );
}

export default ScrollCouponComponent;