import React, { useState, useEffect } from "react";
import { TextField, InputAdornment, Box, Typography, Modal, Button, Autocomplete, Grid, Drawer, useMediaQuery } from "@mui/material";
import { LoadScript, GoogleMap, MarkerF } from "@react-google-maps/api";
import { HomeRounded, LocationOnOutlined, MyLocation, Place, SearchSharp, WorkRounded } from "@mui/icons-material";
import Images from "../../../utils/Images";
import axios from "axios";
import { API, GOOGLE_MAP } from "../../../utils/Api";
import { getPreciseDistance } from 'geolib';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setPickedAddress } from '../../../store/Address/addressSlice';
import { useTheme } from "@emotion/react";
import AddAddressScreen from "./AddAddressScreen";
import PlacesAutocomplete from "../../../components/PlacesComponent";

const AddAddressMapScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const [map, setMap] = useState(null);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [locationDrawer, setLocationDrawer] = useState(true);
  const [cities, setCities] = useState([]);
  const token = localStorage.getItem("token");
  const [selectedCity, setSelectedCity] = useState("");
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [selectedCityData, setSelectedCityData] = useState(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [selectedStore, setSelectedStore] = useState(null);
  const [address, setAddress ] = useState([]);
  const [markerPosition, setMarkerPosition] = useState({ lat: 20.5937, lng: 78.9629 });
  const [zoom, setZoom] = useState(5);
  const [savedUserAddressList, setSavedUserAddressList] = useState([]);
  const [isPermissionGranted, setIsPermissionGranted] = useState(false);
  const [addAddressMapScreen, setAddAddressMapScreen] = useState(false);
  const [addressToAdd, setAddressToAdd] = useState([]);

  const handleCoordinatesChange = async (newCoordinates) => {
    setZoom(5);
    setMarkerPosition(newCoordinates);
    console.log(newCoordinates);
    try {
      const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${newCoordinates.lat}&lon=${newCoordinates.lng}`;
      const response = await fetch(url);
      const data = await response.json();
      setAddress(data);
      dispatch(setPickedAddress(data));
      setAddressToAdd(data);
    } catch (error) {
      console.error("Error getting current location:", error);
    } finally {
      setLoading(false);
    }
    if (map) {
      map.panTo(newCoordinates);
      let currentZoom = map.getZoom();
      const targetZoom = 14;
      const zoomInterval = setInterval(() => {
        if (currentZoom < targetZoom) {
          currentZoom += 1;
          map.setZoom(currentZoom);
        } else {
          clearInterval(zoomInterval);
        }
      }, 200);
    }
  };

  const containerStyle = {
    width: "100%",
    height: isMdUp ? "60vh" : "75vh",
  };

  const center = {
    lat: currentLocation?.lat || 0,
    lng: currentLocation?.lng || 0,
  };

  const onLoad = (map) => {
    setMap(map);
    setMapLoaded(true);
  };

  const fetchLocation = async () => {
    try {
      handleCloseDrawer();
      const position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });
      const { latitude, longitude } = position.coords;
      setLatitude(latitude);
      setLongitude(longitude);
      setMarkerPosition({ lat: latitude, lng: longitude });
      if (map) {
        map.panTo({ lat: latitude, lng: longitude });
        let currentZoom = map.getZoom();
        const targetZoom = 14;
        const zoomInterval = setInterval(() => {
          if (currentZoom < targetZoom) {
            currentZoom += 1;
            map.setZoom(currentZoom);
          } else {
            clearInterval(zoomInterval);
          }
        }, 200);
      }
      const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;
      const response = await fetch(url);
      const data = await response.json();
      setAddress(data);
      dispatch(setPickedAddress(data));
      setAddressToAdd(data);
    } catch (error) {
      console.error("Error getting current location:", error);
      if (error.code === error.PERMISSION_DENIED) {
        console.log('Enable location permission');
        const isPermissionGranted = window.confirm("Location permission is required. Do you want to allow it?");
        if (isPermissionGranted) {
          fetchLocation();
        } else {
          window.prompt("Location permission is required to proceed.");
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const handleAddress = () => {
    if (isMdUp) {
      setAddAddressMapScreen(true);
    } else {
      navigate('/addAddressScreen', { state: { address } });
    }
    // console.log(address);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleOpenDrawer = () => {
    setLocationDrawer(true);
  };

  const handleCloseDrawer = () => {
    setLocationDrawer(false);
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleMarkerDragEnd = async (event) => {
    const newLat = event.latLng.lat();
    const newLng = event.latLng.lng();
    setMarkerPosition({ lat: newLat, lng: newLng });
    setLatitude(newLat);
    setLongitude(newLng);
    const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${newLat}&lon=${newLng}`;
    const response = await fetch(url);
    const data = await response.json();
    setAddress(data);
    setAddressToAdd(data);
  };

  useEffect(() => {
    navigator.permissions.query({ name: 'geolocation' }).then((result) => {
      if (result.state === 'granted') {
        setIsPermissionGranted(true);
        // fetchLocation();
      } else if (result.state === 'prompt') {
        setIsPermissionGranted(false);
      } else if (result.state === 'denied') {
        setIsPermissionGranted(false);
      }
    });
    setMarkerPosition({ lat: 20.5937, lng: 78.9629 });
  },[]);

  const mapLoaderElement = () => {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          flexDirection: 'column',
          textAlign: 'center',
          backgroundColor: '#eaeaea'
        }}
      >
        <LocationOnOutlined sx={{ fontSize: 50 }} />
        <Typography variant="h6">Picking Up <br/>Location</Typography>
      </Box>
    )
  };

  const toggleAddAddressMapScreen = (open) => (event) => {
    setAddAddressMapScreen(open);
  };

  return (
    <LoadScript googleMapsApiKey={GOOGLE_MAP.GOOGLE_MAP_KEY} loadingElement={
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          flexDirection: 'column',
          textAlign: 'center',
          backgroundColor: '#eaeaea'
        }}
      >
        <LocationOnOutlined sx={{ fontSize: 50}} />
        <Typography variant="h6">Picking Up <br/>Location</Typography>
      </Box>
    }>
      <>
      <Box className="searchCityBox" sx={{ left: "12%" }}>
        <PlacesAutocomplete onCoordinatesChange={handleCoordinatesChange} />
      </Box>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={markerPosition}
        zoom={zoom}
        onLoad={onLoad}
        options={{ mapTypeControl: false, zoomControl: false, fullscreenControl: false, keyboardShortcuts: false }}
      >
        {markerPosition.lat && markerPosition.lng && mapLoaded && (
          <MarkerF
            position={markerPosition}
            draggable={true}
            onDragEnd={handleMarkerDragEnd}
            icon={Images.xpanseDeliveryPin}
          />
        )}
      </GoogleMap>
      <Box className="locateMeButton" sx={{ cursor: 'pointer' }} onClick={fetchLocation}> <Place />  Locate Me</Box>
      {address && address.address ? (
        <Grid container className="address-container" sx={{ height: '25%' }}>
          <Grid item xs={9}>
            <Typography className="address">
              <MyLocation sx={{ paddingRight: '5px' }} />
              {address.address.suburb || address.address.village || address.address.town || address.address.county}
            </Typography>
            <Typography className="address2">
              {address.address.state_district}, {address.address.state}, {address.address.postcode}.
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ paddingBottom: '20px'}}>
            <Box className="button" sx={{ cursor: 'pointer' }} onClick={handleAddress}>Continue</Box>
          </Grid>
        </Grid>
      ) : (
        <Grid container className="address-container" sx={{ padding: '30px', textAlign: 'center' }}>
          <Typography variant="body1" sx={{ color: '#186B8C', fontWeight: '700' }}>Move the pin to your location</Typography>
        </Grid>
      )}
      </>
      <Modal open={modalOpen} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "white",
            boxShadow: 0,
            padding: '10px 30px 10px 30px',
            borderRadius: '12px',
            textAlign: 'center'
          }}
        >
          <Typography variant="h6" component="div" gutterBottom>
            No outlets found for your city.
          </Typography>
          <Button onClick={handleCloseModal}>Continue</Button>
        </Box>
      </Modal>
      <Drawer
        anchor="right"
        open={addAddressMapScreen}
        onClose={toggleAddAddressMapScreen(false)}
        classes={{ paper: "drawerPaperForAddAddress" }}
      >
        <Box className="addressAddMapScreen" onClick={(event) => event.stopPropagation()}   sx={{ overflowY: 'auto' }}>
          <AddAddressScreen addressToAdd={addressToAdd} />
        </Box>
      </Drawer>
    </LoadScript>
  );
};

export default AddAddressMapScreen;
