import { Box, Button, Divider, FormControl, FormControlLabel, FormLabel, Grid, IconButton, InputAdornment, Modal, Radio, RadioGroup, TextField, Toolbar, Typography } from '@mui/material';
import React, { useState } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import Images from '../../utils/Images';
// import './Styles/AccountDeletion.css';

const style = {
    position: 'absolute',
    borderRadius: '12px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    justifyContent: 'center',
    bgcolor: 'background.paper',
    border: '2px solid #fff',
    boxShadow: 24,
    p: 4,
};

function DeleteAccountScreen() {

    const accessToken = localStorage.getItem('authToken');
    const [userData, setUserData] = useState({
        mobile: '',
        otp: '',
        isSignup: false
    });
    const [OpenDeleteAccModal, setOpenDeleteAccModal] = useState(false);
    const [selectedReason, setSelectedReason] = useState('');
    const [otherReason, setOtherReason] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserData((prevSate) => ({ ...prevSate, [name]: value }));
    }

    const handleSendOTP = async(e) => {
        e.preventDefault();
        try {
            const response = await axios.post("https://xpanse.dev.bexcart.com/auth/user/send-otp", {
                mobile: userData?.mobile,
            });
            console.log("response", response);
            toast.success("Otp Sent Successfully", {
                position: "top-center",
                autoClose: 2000,
                theme: "colored",
            });
        } catch (error) {
            let errorMessage = error?.response?.data?.data?.message;
            toast.error(errorMessage, {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
        }
    }

    const handleVerifyOtp = async(e) => {
        e.preventDefault();
        try {
            const response = await axios.post("https://xpanse.dev.bexcart.com/auth/user/verify-otp", {
                mobile: userData?.mobile,
                otp: userData?.otp,
                isSignup: false,
            });
            if (response.status === 200) {
                console.log("response", response);
                localStorage.setItem("authToken", response?.data?.data?.token);
                const errorMessage = response?.data?.data?.message;
                toast.success(errorMessage, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
                setOpenDeleteAccModal(true);
                // navigate("/user-details");
            }
        } catch (error) {
            console.log("error", error.response);
            if (error?.response) {
                let errorMessage = '';
                if ( error.response?.status === 406 ) {
                    errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
                } else if ( error.response?.status === 412 ) {
                    errorMessage = error?.response?.data?.data?.title;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error
                }    
                toast.error(errorMessage, {
                position: "top-right",
                autoClose: 3000,
                theme: "colored",
                });
            }
        }
    }

    const handleReasonChange = (event) => {
        setSelectedReason(event.target.value);
    };

    const handleOtherReasonChange = (event) => {
        setOtherReason(event.target.value);
    };

    const handleDeleteAccount = async(e) => {
        e.preventDefault();
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',  // Add this to ensure the request body is treated as JSON
            }
            // Determine the reason (use 'otherReason' if "Other" is selected)
            const reason = selectedReason === 'other' ? otherReason : selectedReason;

            // Create the request body
            const requestBody = {
                reason: reason || 'No reason provided' // Fallback in case of no input
            };
            const response = await axios.delete('https://xpanse.dev.bexcart.com/auth/user/delete-account', { data: requestBody, headers });
            if (response.status === 200) {
                console.log("response", response);
                const errorMessage = response?.data?.data?.message;
                toast.success(errorMessage, {
                    position: "bottom-center",
                    autoClose: 2000,
                    theme: "colored",
                });
                setOpenDeleteAccModal(false);
            }
        } catch (error) {
            let errorMessage = error?.response?.data?.data?.message;
            toast.error(errorMessage, {
                position: "bottom-center",
                autoClose: 2000,
                theme: "colored",
            });
        }
    }

    const handleClose = (e) => {
        setOpenDeleteAccModal(false);
    }

    return (
        <Box className="deleteScreen">
            <div className='account-delete-main'>
                <img src={Images.xpanseNavLogo} style={{ height: '160px', width: '130px', marginTop: '-50px' }} alt="" />
                <Box className='account-delete-box'>
                    <Grid container justifyContent="center" alignItems="center">
                        <Grid item md={12} sm={11} xs={11} >
                            <Box display="flex" justifyContent="center" sx={{ marginBottom: '20px' }} >
                                <Typography variant="h5" component="h2" sx={{ fontWeight: '600', color: '#1E9CED' }}>THINKING OF LEAVING US?</Typography>
                            </Box>
                            <Box sx={{ marginTop: '20px' }}>
                                <label style={{ fontWeight:'600', color: '#898989' }}>Mobile Number</label>
                                <TextField 
                                    fullWidth
                                    type='number'
                                    name="mobile"
                                    variant="outlined"
                                    value={userData?.mobile}
                                    placeholder="Enter Mobile Number"
                                    onChange={handleChange}
                                    onInput={(e) => {
                                        e.target.value = e.target.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Button
                                                    onClick={handleSendOTP}
                                                    variant="contained"
                                                    sx={{ backgroundColor: '#1E9CED', color: '#fff', padding: "13px", borderRadius: '4px', marginRight: '-12px' }}
                                                    disabled={!userData?.mobile || userData?.mobile.length !== 10}
                                                >
                                                    Send OTP
                                                </Button>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Box>
                            <Box sx={{ marginTop: '20px' }}>
                                <label style={{ fontWeight:'600', color: '#898989' }}>OTP</label>
                                <TextField 
                                    fullWidth
                                    type='number'
                                    name='otp'
                                    placeholder="Enter OTP"
                                    variant="outlined"
                                    value={userData?.otp}
                                    onChange={handleChange}
                                    onInput={(e) => {
                                        e.target.value = e.target.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');
                                    }}
                                />
                            </Box>
                            <Grid container justifyContent="center" sx={{ marginTop: '30px' }}>
                                <Grid item xs={12} sm={12} md={12} display="flex" justifyContent="center">
                                    <Button className='submit-button' variant="contained" fullWidth size="large" sx={{ backgroundColor: '#1E9CED', color: '#fff', borderRadius: '20px' }} onClick={handleVerifyOtp}>Submit</Button>
                                </Grid>
                            </Grid>
                        </Grid>            
                    </Grid>
                </Box>

                { OpenDeleteAccModal ? (
                <Modal 
                    open={OpenDeleteAccModal}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ fontWeight: '600' }}>
                            HELP US GET BETTER
                        </Typography>
                        <Divider />
                        <Typography sx={{ margin: '2px', fontWeight: '500' }}>Reason For Leaving Xpanse</Typography>
                        <FormControl component="fieldset" sx={{ marginTop: '10px' }}>
                            <RadioGroup
                                aria-label="reason"
                                name="reason"
                                value={selectedReason}
                                onChange={handleReasonChange}   
                            >
                                <FormControlLabel value="didNotFindProducts" control={<Radio />} label="Did not find products" />
                                <FormControlLabel value="deliveryNotSatisfactory" control={<Radio />} label="Delivery wasn't satisfactory" />
                                <FormControlLabel value="betterDiscounts" control={<Radio />} label="Better discounts elsewhere" />
                                <FormControlLabel value="appIsDifficultToUse" control={<Radio />} label="App is difficult to use" />
                                <FormControlLabel value="other" control={<Radio />} label="Other" />
                            </RadioGroup>

                            {selectedReason === 'other' && (
                                <TextField
                                    label="Please specify"
                                    variant="outlined"
                                    multiline
                                    rows={3}
                                    fullWidth
                                    margin="normal"
                                    value={otherReason}
                                    onChange={handleOtherReasonChange}
                                />
                            )}

                            <Button variant='outlined' color='error' sx={{ borderRadius: '18px', marginTop: '10px' }} onClick={handleDeleteAccount}>Submit & Delete Account</Button>
                        </FormControl>
                    </Box>
                </Modal>
                ) : null }

            </div>
        </Box>
    )
}

export default DeleteAccountScreen;