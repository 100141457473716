import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { API } from '../../utils/Api';

const initialState = {
  products: [],
  categories: [],
  status: 'idle',
  error: null,
};

const getTokenFromLocalStorage = () => {
  return localStorage.getItem('token');
};

const token = getTokenFromLocalStorage();

export const fetchProducts = createAsyncThunk('products/fetchProducts', async ( categoryId , { getState }) => {
  try {
    const url = `${API.BASE_URL}product/List?category=${categoryId}&search=`;
    console.log(url);
    const response = await axios.get(url);
    console.log('Products', response.data.data.products);
    return response.data.data.products;
  } catch (error) {
    throw error;
  }
});

export const fetchCategories = createAsyncThunk('products/fetchCategories', async () => {
  try {
    const response = await axios.get(`${API.BASE_URL}masters/category/getCategoryWithProducts`);
    return response.data.data.categories;
  } catch (error) {
    throw error;
  }
});

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.products = action.payload;
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchCategories.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.categories = action.payload;
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const {  } = productsSlice.actions;

export default productsSlice.reducer;