import { Box, Button, TextField } from '@mui/material';
import React, { useCallback } from 'react'
import useRazorpay from "react-razorpay";

const UPIScreen = () => {
  const [Razorpay] = useRazorpay();

    const handlePayment = useCallback(async () => {
        const options = {
            key: "rzp_test_CuEVwONWGPg8yo",
            amount: 2000 * 100,
            currency: "INR",
            name: "Xpanse",
            order_id: "order_Nlob64dwUdiKna",
            email: 'amit@gmail.com',
            vpa: "success@razorpay",
            prefill: {
                method: "upi",
            }
        };
        if (Razorpay) {
        const rzpay = new Razorpay(options);
        rzpay.open(options);
        }
    }, [Razorpay]);

    return (
        <Box>
            <TextField
                variant="outlined"
                placeholder="Search"
            />
            <button onClick={handlePayment}>Heyyyy</button>
        </Box>
    )
}

export default UPIScreen;
