import { Box, Fade, Slide, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import StoreIcon from '@mui/icons-material/Store';
import { KeyboardArrowDown } from '@mui/icons-material';
import Images from '../utils/Images';
import { useNavigate } from 'react-router-dom';
import { SearchBar } from './SearchBar';

const StoreHeader = () => {
  const [store, setStore] = useState([]);
  const [userData, setUserData] = useState(null);
  const [showSearch, setShowSearch] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setStore(JSON.parse(localStorage.getItem('selectedStoreData')));
    setUserData(JSON.parse(localStorage.getItem('userDataLogin')));
  }, []);

  const handleProfileClick = () => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/profileScreen');
    } else {
      navigate('/mobileNumber');
    }
  };

  const handleSearchClick = () => {
    setShowSearch((prev) => !prev);
  };

  return (
    <>
      <Box className="storeHeader"
        sx={{
          backgroundImage: `url(${Images.snowFall})`, 
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          position: 'relative',
          zIndex: 0,
        }}
      >
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ paddingRight: '10px' }}>
            <img
              onClick={handleProfileClick}
              className="profileImage"
              src={userData?.profileImage || Images.profileHead}
              alt=""
            />
          </Box>
          <Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <StoreIcon className="storeIcon" />
              <Typography
                sx={{ fontSize: '14px', fontWeight: '500', color: '#0E4961', marginTop: '2px' }}
              >
                {store?.name}
              </Typography>
            </Box>
            <Typography className="storeAddress2">{store?.address}</Typography>
            <Box onClick={() => navigate('/advertiseScreen')} sx={{ display: 'flex' }}>
              <Typography className="changeLocation">Change Locations</Typography>
              <KeyboardArrowDown sx={{ fontSize: '18px' }} />
            </Box>
          </Box>
        </Box>
        <Box>
          <img onClick={handleSearchClick} src={showSearch ? Images.closeSearch : Images.searchBarImage} alt="" />
        </Box>
      </Box>

      {showSearch && (
        <>
          <Slide direction="down" in={showSearch} mountOnEnter unmountOnExit timeout={300}>
            <Box
              sx={{
              }}
            >
              {showSearch && <SearchBar />}
            </Box>
          </Slide>
        </>
      )}
    </>
  );
};

export default StoreHeader;
