import React, { useState } from 'react';
import { Box, TextField, InputAdornment, IconButton, Dialog, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import MicIcon from '@mui/icons-material/Mic';
import { Mic } from '@mui/icons-material';
import Images from '../utils/Images';
import { Player } from '@lottiefiles/react-lottie-player';
import { useDispatch, useSelector } from 'react-redux';
import { setSearchText } from '../store/Search/SearchSlice'; 

const SearchBar = () => {
  const dispatch = useDispatch();
  const searchText = useSelector((state) => state.search.searchText);
  const [isListening, setIsListening] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [spokenText, setSpokenText] = useState('');

  const handleSearchChange = (event) => {
    const value = event.target.value;
    dispatch(setSearchText(value)); 
  };

  const handleVoiceSearch = () => {
    const recognition = new window.webkitSpeechRecognition();
    recognition.lang = 'en-US';
    recognition.onstart = () => {
      setIsListening(true);
      setOpenModal(true);
    };
    
    const timeoutId = setTimeout(() => {
      recognition.stop();
      console.log("Timeout - stopping recognition");
    }, 4000); 

    recognition.onend = () => {
      clearTimeout(timeoutId);
      setIsListening(false);
      setOpenModal(false);
    };

    recognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript;
      setSpokenText(transcript);
      dispatch(setSearchText(transcript)); // Dispatch the spoken text to Redux
      recognition.stop();
    };

    recognition.start();
  };

  return (
    <>
      <Box className="searchBar">
        <TextField
          placeholder={isListening ? "Listening for your choice..." : "Search for the perfect brew"}
          className="bar"
          variant="outlined"
          size="small"
          fullWidth
          value={searchText}
          onChange={handleSearchChange}
          sx={{ 
            backgroundColor: '#fff',
            '& .MuiOutlinedInput-root': {
              borderRadius: '7px',
              '& fieldset': {
                borderColor: '#1DA1F2',
              },
              '&:hover fieldset': {
                borderColor: '#09A5F7',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#09A5F7',
              },
            },
            '& .MuiInputBase-input::placeholder': {
              fontSize: '14px', 
              color: '#747474',
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" sx={{ marginRight: '-15px' }}>
                <IconButton style={{ color: '#1DA1F2' }} onClick={() => dispatch(setSearchText(searchText))}>
                  <SearchIcon />
                </IconButton>
                <span style={{ width: '1px', height: '1.5em', backgroundColor: '#ccc', margin: '0px' }}></span>
                <IconButton style={{ color: '#1DA1F2' }} onClick={handleVoiceSearch}>
                  <Mic />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
      
      <Dialog open={openModal} onClose={() => setOpenModal(false)}>
        <Box sx={{ backgroundColor: 'transparent', p: 3, textAlign: 'center' }}>
          <Typography variant="h6">{isListening ? "Listening..." : "Processing..."}</Typography>
          <Typography variant="body1" sx={{ mt: 2 }}>{spokenText}</Typography>
          <Player autoplay loop src={Images.micAnimation} className="micAnimation" />
        </Box>
      </Dialog>
    </>
  );
};

export { SearchBar };
