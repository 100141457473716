import React from 'react';
import { Box, Typography } from '@mui/material';

const RefundCancelPolicy = () => {
  return (
    <div className='privacyScreen'>
        <Typography className='privacyTitle'>REFUND AND CANCELLATION POLICY</Typography>
        <Box className='privacyPolicyContainer'>
            <div>
                <Typography className='lastUpdatedText'>LAST UPDATED: 20-09-2024</Typography>
                <p className='privacyPolicyDesc'>
                    At Xpanse Café, we strive to provide an exceptional experience for our customers. However, we understand that unforeseen circumstances may necessitate the cancellation of an order or a refund request. Please review our detailed policies below to understand your rights and obligations.
                </p>
            </div>
            <br/>
            <div>
                <Typography className='aboutUsText'>Cancellation Policy:</Typography>
            </div>
            <div>
                <Typography className='purposeText'>1. Free Cancellation Period:</Typography>    
                <p className='privacyPolicyDesc'>
                    You may cancel your order within 30 seconds of placing it without any charges. This window is intended to provide flexibility for last-minute changes. Cancellations can be made directly through the app under the "Order History" section.
                </p>
            </div>
            <div>
                <Typography className='purposeText'>2. Post 30-Second Cancellation:</Typography>    
                <p className='privacyPolicyDesc'>
                    After the 30-second grace period, orders enter the preparation stage and cannot be cancelled or modified. This includes changes to the delivery address, order items, or contact information. At this point, all sales are final, and no refunds will be provided for cancellation requests beyond the allowed time frame.
                </p>
            </div>
            <div>
                <Typography className='purposeText'>3. Order Cancellation Due to Product Unavailability:</Typography>    
                <p className='privacyPolicyDesc'>
                    In the event that the item you ordered is unavailable at the café, Xpanse reserves the right to cancel your order. In such cases, if payment has already been made, we will refund the full amount by reversing the transaction. The refund initiation will begin within 24 hours and the refunded amount will be processed back to your original payment method within 5 to 7 working days. For assistance regarding the refund status, please contact our customer service at contact@xpansecafe.com.
                </p>
            </div>
            <div>
                <Typography className='purposeText'>4. Payment Failure:</Typography>    
                <p className='privacyPolicyDesc'>
                    If your payment fails during the checkout process, the order will not be confirmed or processed. Please verify your payment details or try an alternative payment method to ensure a successful transaction. If repeated failures occur, feel free to reach out to us at contact@xpansecafe.com for assistance.
                </p>
            </div>
            <div>
                <Typography className='purposeText'>5. Force Majeure Events:</Typography>    
                <p className='privacyPolicyDesc'>
                    Xpanse Café reserves the right to cancel orders due to unavoidable circumstances such as natural disasters, extreme weather, strikes, or other force majeure events. If this occurs, we will notify you immediately, and a full refund will be processed if payment has been made. Refund timelines are subject to your bank’s processes and typically take 5 to 7 working days. For further inquiries, please contact contact@xpansecafe.com.
                </p>
            </div>
            
            <br/>
            <br/>

            <div>
                <Typography className='aboutUsText'>Refund Policy:</Typography>
            </div>

            <div>
                <Typography className='purposeText'>1. Non-Refundable Items:</Typography>    
                <p className='privacyPolicyDesc'>
                    All perishable food and beverage items are non-refundable, except in cases where Xpanse Café delivers a wrong or damaged order. Perishable items cannot be returned due to their nature, but we will address any issues related to incorrect or compromised deliveries.
                </p>
            </div>
            
            <div>
                <Typography className='purposeText'>2. Refund Eligibility:</Typography>    
                <p className='privacyPolicyDesc'>
                    You may be eligible for a refund if:
                    <ul>
                        ●	You received the wrong order (items not matching your original order).
                        <br/>●	Your order was damaged during preparation or delivery (e.g., packaging issues or product spillage).
                        <br/>●	To qualify for a refund, please contact our customer care team within 24 hours of receiving your order at contact@xpansecafe.com Include your order number, photos of the incorrect or damaged items, and a detailed description of the issue.
                    </ul>
                </p>
            </div>

            <div>
                <Typography className='purposeText'>3. Refund Process:</Typography>    
                <p className='privacyPolicyDesc'>
                    Once your refund request is approved, Xpanse Café will initiate the refund through the original payment method. We will process the refund immediately, but please be aware that the timeline for receiving the funds depends on your bank or payment service provider. Refunds typically take 5 to 7 working days to appear in your account. If you have not received the refund within this period, please contact your bank or reach out to us at contact@xpansecafe.com for further assistance.
                </p>
            </div>

            <div>
                <Typography className='purposeText'>4. Refunds for Digital Payments:</Typography>    
                <p className='privacyPolicyDesc'>
                    If an order placed using a digital payment method (credit/debit card, UPI, mobile wallets, etc.) is cancelled due to product unavailability, the refund will be automatically processed back to the original payment source. The refund is initaited within the first 24 hours and the refunds typically take 5 to 7 working days, depending on the respective bank or payment service provider. Should you experience delays, you may contact us at contact@xpansecafe.com
                </p>
            </div>

            <div>
                <Typography className='purposeText'>5. No Refunds for Post 30-Second Cancellations:</Typography>    
                <p className='privacyPolicyDesc'>
                    As stated in our cancellation policy, any orders cancelled after the 30-second window are not eligible for a refund. We urge customers to carefully review their orders before placing them to avoid any inconveniences. If you require additional clarification on this policy, please feel free to contact our team at contact@xpansecafe.com, +91 80076 65755.
                </p>
            </div>

            <div>
                <Typography className='purposeText'>Contact Us:</Typography>    
                <p className='privacyPolicyDesc'>
                    If you have any further questions or concerns regarding your cancellation, refund, or any other aspect of your order, please feel free to reach out to our dedicated customer service team at:
                    <br/>
                    - Customer Support: contact@xpansecafe.com, +91 80076 65755
                    <br/>
                    <br/>
                    We are committed to ensuring that your experience with Xpanse Café is smooth and satisfactory. Thank you for your understanding and cooperation.
                </p>
            </div>
        </Box>
    </div>
  )
}

export default RefundCancelPolicy;
