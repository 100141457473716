import React, { useEffect, useState } from 'react';
import { Badge, BottomNavigation, BottomNavigationAction, Box } from '@mui/material';
import { Home, TrendingUp, Favorite, AccountCircle, AccessTime, PictureAsPdf } from "@mui/icons-material";
import { useLocation, NavLink } from 'react-router-dom';
import Images from '../utils/Images';
import { useDispatch, useSelector } from 'react-redux';
import { setData } from '../store/Cart/cartSlice';
import { API } from '../utils/Api';
import axios from 'axios';

const BottomNav = () => {
  const token = localStorage.getItem("token");
  const [value, setValue] = useState(0);
  const location = useLocation();
  const dispatch = useDispatch();
  const cartData = useSelector(state => state.cart.data);
  const orderType = localStorage.getItem("selectedOrderType");
  const [outletData, setOutletData] = useState(JSON.parse(localStorage.getItem('selectedStoreData')));
  const data = useSelector(state => state?.cart?.data);

  const routeToIndex = {
    '/offerScreen': 0,
    '/productsListing': 1,
    '/cartScreen': 2,
    '/profileScreen': 3,
  };

  useEffect(() => {
    setOutletData(JSON.parse(localStorage.getItem('selectedStoreData')));
    const fetchCartData = async () => {
      try {
        const response = await axios.get(`${API.BASE_URL}cart/userCart/${outletData._id}/${orderType}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        dispatch(setData(response?.data));
        // console.log('Cart data fetch', response.data);
      } catch (error) {
        dispatch(setData([]));
        // console.log('Cart Screen Error', error?.response?.data);
      }
    }; 
    fetchCartData();
  }, [dispatch]);

  const initialValue = routeToIndex[location.pathname] || 0;

  if (value !== initialValue) {
    setValue(initialValue);
  }

  const totalQuantity = data?.data?.cartItems?.reduce((acc, item) => acc + item.quantity, 0) || 0;

  return (
    <Box sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 999, width: '100%' }}>
      <BottomNavigation
        value={value}
        onChange={(event, newValue) => setValue(newValue)}
        showLabels
        className="bottomNav"
      >
        <BottomNavigationAction
          component={NavLink}
          to="/offerScreen"
          label="Offers"
          icon={<img src={value === 0 ? Images.xpanseOfferActive : Images.xpanseOfferUnActive} alt="Offers" />}
          className={value === 0 ? 'activeNav' : 'inactiveNav'}
        />
        <BottomNavigationAction
          component={NavLink}
          to="/productsListing"
          label="Menu"
          icon={<img src={value === 1 ? Images.xpanseBottomNavLogoActive : Images.xpanseBottomNavLogo} alt="Menu" />}
          className={value === 1 ? 'activeNav' : 'inactiveNav'}
        />
        <BottomNavigationAction
          component={NavLink}
          to="/cartScreen"
          label="Cart"
          icon={
            <Badge badgeContent={totalQuantity} color="primary">
              <img src={value === 2 ? Images.xpanseCartActive : Images.xpanseCartUnActive} alt="Cart" />
            </Badge>
          }
          // className="navLink"
          className={value === 2 ? 'activeNav' : 'inactiveNav'}
        />
        <BottomNavigationAction
          component={NavLink}
          to="/profileScreen"
          label="Profile"
          // icon={<AccountCircle />}
          icon={value === 3 ? <AccountCircle /> : <AccountCircle color='grey' /> }
          className={value === 3 ? 'activeNav' : 'inactiveNav'}
        />
      </BottomNavigation>
    </Box>
  );
};

export default BottomNav;

