import React from 'react';
import { Box, Typography } from '@mui/material';

function TermsAndCondition() {
    return (
        <div className='termsAndConditionScreen'>
            <Typography className='termsTitle'>TERMS AND CONDITIONS</Typography>
            <Box className="termsContainer">
                    {/* <Typography className='agreement'>Agreement</Typography>
                    <Typography className='termServices'>Terms of Services</Typography> */}
                <Typography className='lastUpdated'>Last Updated on 20-09-2024</Typography>
                <div>
                    <p>Please take a moment to carefully read through these terms. By downloading, installing, or accessing the Xpanse Mobile Application (“Application”), you’re entering into a legally binding agreement with Xpanse (“we,” “us,” or “our”). These Mobile Application Terms of Use (“Terms”) guide your use of the Application, and by continuing, you confirm that you’ve read, understood, and agreed to these Terms. You also confirm that you’ve read and understood our Privacy Policy.
                        <br/><br/>In addition to these Terms, you agree to follow any additional policies and terms related to the Application, as well as any other rules, guidelines, or procedures we may publish from time to time. All of these are incorporated into these Terms by reference. By signing up for an account on the Xpanse Mobile Application, you agree to abide by them.</p>
                    <Typography className='termsHeading'>1. Updates to These Terms</Typography>
                    <Typography className='termsDesc'>We reserve the right to modify, change, or update these Terms and any related policies at any time, at our discretion, and without prior notice. Should any significant changes occur, we will make reasonable efforts to notify you, such as through pop-up notifications. Your continued use of the Application after changes have been introduced will mean that you accept those changes.</Typography>

                    <Typography className='termsHeading'>2. Use of the Application</Typography>
                    <Typography className='termsDesc'>To use this Application, you must be at least eighteen (18) years of age and have a valid Xpanse account ("Account"). By registering for an Account, you represent and confirm that you are at least eighteen (18) years old. If you are under eighteen (18) (“Minor User”), you may not use the Application or register for an Account without the consent of your parent or legal guardian. If you are a Minor User who has accessed the Application or registered for an Account without such consent, you must discontinue using the Application immediately.
                        <br/>For parents or legal guardians (“Parent User”) of a Minor User, if you consent to their use of the Application and their registration for an Account, you confirm that you are responsible for ensuring they comply with these Terms. By approving a Minor User’s access to the Application, you agree to be bound by these Terms and are fully liable for any actions taken by the Minor User.
                    </Typography>

                    <Typography className='termsHeading'>3. Account Registration and Cancellation</Typography>
                    <Typography className='termsDesc'>Certain functions of this Application are only accessible to users who have registered for an Account. When creating an Account, you’ll need to provide a valid mobile phone number and/or email address for verification purposes. If you choose not to provide this information, you will not be able to use the Application’s services.
                    <br/>By registering for an Account, you confirm that:
                    <ul>
                        •	Your Account is for personal, non-commercial use only.
                        <br/>•	The information you provide is accurate, complete, and up to date.
                        <br/>•	You will promptly update any changes to your information.
                        <br/>•	You are responsible for maintaining the security of your Account, including your password.
                        <br/>•	You will immediately notify us if you detect any security risks or breaches.
                        <br/>•	You take full responsibility for any activities occurring under your Account.
                    </ul>
                    If we discover that you have violated these Terms or that activities on your Account may harm the Application or violate third-party rights or laws, we reserve the right to suspend or terminate your Account without prior notice.
                    If you lose access to your Account, you may request assistance from customer service, but we cannot guarantee verification of ownership. You are solely responsible for safeguarding your Account credentials and bear full responsibility for any damage caused by unauthorized access, hacking, or password loss.
                    <br/>As a Registered User, you can carry out various activities on the Application, such as placing orders, topping up Coffee Credits, and purchasing vouchers. Should you decide to cancel your Account, all existing vouchers will become invalid, and no refunds will be provided for unused vouchers or remaining balances in your Coffee Credits. Additionally, once an Account is cancelled, all associated data will be deleted or anonymized and cannot be recovered.
                    </Typography>

                    <Typography className='termsHeading'>4. User License</Typography>
                    <Typography className='termsDesc'>You are granted a personal, non-exclusive, non-transferable, limited, and revocable license to use the Application solely for personal, non-commercial purposes. All rights related to the Application remain the exclusive property of Xpanse, and no rights are transferred to you under these Terms. Xpanse reserves the right to revoke this license at any time.<br/>
                        By using the Application, you agree to the following:
                        <ul>
                            •	You will not harass, stalk, or defame others.
                            <br/>•	You will not use the Application for unlawful, fraudulent, or deceptive activities.
                            <br/>•	You will not use automated systems (e.g., "robots" or "spiders") to access the Application.
                            <br/>•	You will not introduce viruses or harmful code to the Application.
                            <br/>•	You will not impersonate others or use technology that harms the Application’s functionality.
                            <br/>•	You will not engage in any activities that violate laws or regulations.
                        </ul>
                        If you violate these Terms, we reserve the right to revoke your license to use the Application without prior notice.
                    </Typography>
                    
                    <Typography className='termsHeading'>5. Services</Typography>
                    <Typography className='termsDesc'>The Application offers various services, such as placing orders, making payments, purchasing vouchers, providing feedback, and participating in promotions. The availability of services may vary depending on the user. Xpanse is entitled, in its own discretion, to adjust and determine the types of services provided on the Application, and the available services may differ from user to user.</Typography>

                    <Typography className='termsHeading'>6. Placement of Orders and Delivery</Typography>
                    <Typography className='termsDesc'>Orders must be placed exclusively via the Xpanse app. Once placed, the order will be confirmed through an in-app notification and an email receipt. 
                    <br/>
                    To begin using the Xpanse Mobile Application, first-time users must download the app from the Play Store. Upon installation, you will need to register by providing your Name, Phone Number, and Email Address. This information is required to create your Xpanse account and to access the features and services of the Application. By completing the registration process, you agree to the terms outlined in this document.
                    <br/>
                    Upon Loggin in, you may place your order on the Application, and select the option of self-pick-up or delivery. The Application will then provide you a list of available coffee shops nearby. You are responsible to select your preferred coffee shop and place your order with the store that you have selected and select the time slot available. Users can cancel their order within 30 seconds after placing it, but no cancellations will be allowed after this time frame.
                    <br/>
                    If you opt for self-pick-up, please note the estimate time of when your order will be ready. You are responsible to collect your order from the selected coffee shop. If you fail to collect your order within 30 Minutes, Xpanse reserves the right to discard your order for food safety and hygiene reasons. In the event your order is discarded due to actions or omissions on your end, you are not entitled to a refund for the discarded order.
                    <br/>
                    When opting for delivery, it is your responsibility to provide accurate and complete details for the recipient, the delivery address, and contact information. Xpanse uses third-party delivery partners to fulfill your order. If delivery fails due to inaccurate or incomplete details provided by you (e.g., incorrect address or phone number), Xpanse will not be held liable, and you will bear any losses. Delivery fees vary and will be displayed at checkout before payment is made.
                    In the event of damage during delivery, Xpanse is not responsible for any harm caused by the delivery partner. You should directly contact the delivery partner regarding any losses or damages arising during transit.
                    To complete your order, you are required to pay the full amount shown at checkout, unless: <br/>
                        <ul>
                            •	Xpanse is unable to fulfill the order and cancels it; or
                            <br/>•	Delivery services are unavailable at the time of your order.        
                        </ul>
                    While Xpanse and its delivery partners strive to ensure timely delivery, there is no guarantee that your order will always be delivered on time. Delivery speed may be affected by factors such as weather, traffic, equipment issues, or Force Majeure events as detailed in Clause 17 below.
                    </Typography>
                    
                    <Typography className='termsHeading'>7. Payment</Typography>
                    <Typography className='termsDesc'>You may make payment for the orders you placed or to purchase vouchers via your Account on the Application. Please note that Xpanse does not accept cash as a form of payment. <br/>
                    Payment may be made using any of the payment methods prescribed by Xpanse from time to time. When you place an order or purchase a product, actual payment will only be charged upon Xpanse acceptance of your order.
                    <br/>
                    You understand and agree that you are subject to the applicable terms and conditions or user agreement of your selected payment method. You may not claim against Xpanse for any failure, disruption or error in connection with your chosen payment method. Xpanse reserves the right, in its sole discretion and at any time, to modify or discontinue, temporarily or permanently, any payment method without giving you notice or giving any reasons. You may make payment by the following:
                    <br/>
                    Voucher: If you use a voucher, please read the voucher-related explanatory note carefully, which will appear via a pop-up notification on the Application before you check out your orders or purchases. You may also access the Coffee Credits to view the instructions.
                    <br/>
                    If you fail to complete the payment within the time indicated on the page according to the terms and conditions of the payment method you choose, or cancel the payment for any reason, Xpanse has the right to terminate your order.
                    <br/>
                    For any questions about refunds, please contact our customer service. We will, on a case-by-case basis, assess your request for a refund and determine, in our sole discretion, if you are entitled to a refund. All refunds shall be made via the original payment mechanism and to the person who made the original payment. You understand that we are unable to offer or provide you with any guarantee regarding the timeliness of the refunds reaching you. The processing of payment may take time and is subject to the respective banks and payment service providers' internal processing timeline. We reserve the right to modify the mechanism of processing refunds at any time without providing you with notice. 
                    </Typography>

                    <Typography className='termsHeading'>8. Communications</Typography>
                    <Typography className='termsDesc'>When registering for an Account, you may choose to opt-in to receive promotional and marketing materials from Xpanse. Depending on your preference, we may send these materials via email, SMS, or other means of communication.
                    <br/>For iOS users, you will receive a prompt upon downloading the Application to accept or reject notifications. If you accept, you may receive both in-app and out-of-app notifications. For Android users, you will need to manually adjust your device settings to opt out of notifications.
                    <br/>Please refer to our Privacy policy to understand how we handle your information.
                    </Typography>
                    
                    <Typography className='termsHeading'>9. Intellectual Property</Typography>
                    <Typography className='termsDesc'>For the purposes of these Terms, "Intellectual Property" means any and all rights in and in relation to patents, trademarks, service marks, and other marks, logos, get-up, trade and business names, internet domain names, designs (and applications for all the same), copyright, moral rights, database rights, rights in know-how, trade secrets, confidential information, inventions, discoveries, improvements, techniques, computer programs and other confidential processes and information and know-how, in each case whether capable of being registered, registered or unregistered and including applications for registration and all rights or forms of protection having equivalent or similar effect elsewhere in the world and rights in the nature of unfair competition rights and rights to sue for passing off.
                    <br/>Xpanse retains all rights, title, and interest (including all Intellectual Property rights) in and to the Application (and the materials found on the Application), including any updates or customisations of any of the foregoing, and any feedback, comments and suggestions submitted by you regarding our current or future products and services. To the extent that such rights, title and interest in and to the feedback, comments and suggestions that you submitted do not automatically vest in Xpanse and cannot be assigned to Xpanse as above, you hereby grant Xpanse an exclusive, perpetual, universal, irrevocable, transferable, sub-licensable, royalty-free and fully paid-up right and licence to use and exploit any of such rights, title and interest (including following the expiry or termination of your Account).
                    <br/>You are not allowed to use any of Xpanse's Intellectual Property without Xpanse's prior written consent. You shall not apply for the registration of Xpanse's Intellectual Property, nor shall you indicate or represent to others, directly or indirectly, that you have the right to display, use or handle Xpanse's Intellectual Property.
                    <br/>No licences, titles or interests are granted to you by implication or otherwise under any Intellectual Property rights owned or controlled by Xpanse, except for the licences and rights expressly granted in these Terms.
                    </Typography>

                    <Typography className='termsHeading'>10. Indemnity</Typography>
                    <Typography className='termsDesc'>To the fullest extent permissible under applicable laws, you agree to defend, indemnify and hold harmless Xpanse, is parent and other affiliated companies (collectively, "Xpanse Group") and Xpanse Group's officers, directors, employees, agents, contractors, advisors and consultants from any and all claims, liability, costs, and expenses, including but not limited to attorney's fees and expenses, arising from or is in connection with (i) your access, use and misuse of the Application, (ii) your violation of these Terms, (iii) your violation of any applicable laws or regulations, (iv) your violation of the rights of others, and/or (v) your willful misconduct.
                    <br/>Xpanse reserves the right to assume the exclusive defense and control of any matter for which you are required to indemnify Xpanse and you agree to cooperate with Xpanse with its defense of these claims.
                    </Typography>

                    <Typography className='termsHeading'>11. Disclaimer and Exclusion of Warranties</Typography>
                    <Typography className='termsDesc'>Nothing in these Terms shall affect any statutory rights that cannot be contractually altered or waived.
                    <br/>While Xpanse will use reasonable efforts to ensure that the availability of the Application, you acknowledge and understand that the availability of the Application and its related services is dependent on external factors that are not within Xpanse's control. Xpanse makes no warranty or representation to you that your use of the Application and its related services will be uninterrupted, timely or free from error.
                    <br/>To the fullest extent permissible under applicable laws, no conditions, representations, guarantees, warranties or other terms (including any implied terms as to the satisfactory quality, fitness for purpose or conformance with description) apply to the Application and its related services except to the extent expressly set out in these Terms.
                    <br/>Xpanse is entitled to modify, revise or suspend the availability of the Application and its related services at any time without notice.
                    </Typography>

                    <Typography className='termsHeading'>12. Limitation of Liability</Typography>
                    <Typography className='termsDesc'>Nothing in these Terms exclude or limit Xpanse's liability for losses that may not be lawfully excluded or limited by applicable laws.
                    <br/>Notwithstanding the above, Xpanse Group and its officers, directors, employees, agents, contractors, advisors and consultants shall not be liable to you for your use of the Application, any loss of profit (whether incurred directly or indirectly), any loss of goodwill, any loss of opportunity, any loss of income, any loss of data, and any indirect or consequential losses which you may incur.
                    </Typography>

                    <Typography className='termsHeading'>13. Governing Law and Dispute Resolution</Typography>
                    <Typography className='termsDesc'>These Terms (including all other terms and conditions referenced to or incorporated herein) without regard to conflict of laws rules. Any dispute, claim, controversy or difference arising out of or in connection with these Terms shall be subject to the exclusive jurisdiction of Courts.</Typography>

                    <Typography className='termsHeading'>14. Assignment</Typography>
                    <Typography className='termsDesc'>Xpanse may assign its rights or transfer its obligations under these Terms, in whole or in part, to any third party at its sole discretion.</Typography>

                    <Typography className='termsHeading'>15. Entire Agreement</Typography>
                    <Typography className='termsDesc'>Except as they may be supplemented by additional terms, conditions, policies, guidelines, standards, and in-product disclosures, these Terms (including all other terms and conditions referenced to or incorporated herein) constitute the full and complete understanding and the entire legal agreement between you and Xpanse, and govern your use of the Application, and completely supersede any prior discussions or agreements, whether oral or written, between you and Xpanse in relation to the Application. </Typography>

                    <Typography className='termsHeading'>16. Severance</Typography>
                    <Typography className='termsDesc'>If any provision or part-provision of these Terms is or becomes invalid, illegal or unenforceable, it shall be deemed deleted, but its invalidity, illegality or unenforceability shall not affect the validity, legality or enforceability of the rest of these Terms.</Typography>
                
                    <Typography className='termsHeading'>17. Force Majeure</Typography>
                    <Typography className='termsDesc'>Xpanse shall not be liable for the non-performance, error, interruption or delay in its performance of obligations under these Terms if this is due, in whole or in part, directly or indirectly, to an event or failure which is unavoidable, beyond our reasonable control or which renders impossible the performance of such obligation.</Typography>
                
                    <Typography className='termsHeading'>18. Waiver</Typography>
                    <Typography className='termsDesc'>Xpanse's failure, delay or neglect to enforce, at any time, the rights or provisions of these Terms will not constitute a waiver of such right or provisions or of the right to enforce such provisions subsequently.</Typography>
                
                    <Typography className='termsHeading'>19. Rights of Third Parties</Typography>
                    <Typography className='termsDesc'>Other as expressly set out in these Terms, a person or entity who is not a party to these Terms shall have no right under applicable laws to enforce any provisions of these Terms.</Typography>
                
                    <Typography className='termsHeading'>20. Customer Service</Typography>
                    <Typography className='termsDesc'>If you have any questions about these Terms or our services, you may contact us via
                    <br/>Email contact@xpansecafe.com.
                    </Typography>
                
                </div>
            </Box>
        </div>
    )
}

export default TermsAndCondition;