import React from "react";
// import "./Styles/Faqs.css";
import { Box, Typography } from "@mui/material";
import Accordion from '@mui/material/Accordion';
// import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AddIcon from '@mui/icons-material/Add';

function FAQs() {
  return (
    <Box className="faqScreen">
      <div>
        <Typography className="faqsTitle">FAQs</Typography>
      </div>
      <Box>
        <div className="accordions">
            <Accordion defaultExpanded>
                <AccordionSummary
                className="accordianTitle"
                expandIcon={<AddIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                >
                Where can I watch?
                </AccordionSummary>
                <AccordionDetails className="accordianDesc">
                Nibh quisque suscipit fermentum netus nulla cras porttitor euismod nulla. Orci, dictumst nec aliquet id ullamcorper venenatis. Fermentum sulla craspor ttitore  ismod nulla. Elit adipiscing proin quis est consectetur. Felis ultricies nisi, quis malesuada sem odio. Potenti nibh natoque amet amet, tincidunt ultricies et. Et nam rhoncus sit nullam diam tincidunt condimentum nullam.
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                className="accordianTitle"
                expandIcon={<AddIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
                >
                Tempus magna risus interdum ultricies sed urna?
                </AccordionSummary>
                <AccordionDetails className="accordianDesc">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                eget.
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                className="accordianTitle"
                expandIcon={<AddIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
                >
                Augue in nibh urna volutpat mattis?
                </AccordionSummary>
                <AccordionDetails className="accordianDesc">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                eget.
                </AccordionDetails>
                {/* <AccordionActions>
                <Button>Cancel</Button>
                <Button>Agree</Button>
                </AccordionActions> */}
            </Accordion>
            <Accordion>
                <AccordionSummary
                className="accordianTitle"
                expandIcon={<AddIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
                >
                Eu egestas sed sed posuere ultrices ?
                </AccordionSummary>
                <AccordionDetails className="accordianDesc">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                eget.
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                className="accordianTitle"
                expandIcon={<AddIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
                >
                Elementum facilisi aliquam, nisi, orci vulputate?
                </AccordionSummary>
                <AccordionDetails className="accordianDesc">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                eget.
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                className="accordianTitle"
                expandIcon={<AddIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
                >
                Nibh at odio dolor etiam neque in vel id orci?
                </AccordionSummary>
                <AccordionDetails className="accordianDesc">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                eget.
                </AccordionDetails>
            </Accordion>
        </div>
      </Box>
    </Box>
  );
}

export default FAQs;
