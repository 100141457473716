import { Box, Drawer, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Images from '../utils/Images';
import axios from 'axios';
import { API } from '../utils/Api';
import { useDispatch, useSelector } from 'react-redux';
import { setActivePickTime } from '../store/Cart/timeSlice';
import { DeliveryDiningOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import SavedAddressesScreen from '../screens/App/ProfileScreens/SavedAddressesScreen';
import DeliveryScreen from '../screens/App/DeliveryScreen';

const DeliveryAddressComponent = ({ addressUpdated }) => {
  const [address, setAddress] = useState([]);
  const [savedAddressDrawer, setSavedAddressDrawer] = useState(false);
  const [selectedDeliveryAddressType, setSelectedDeliveryAddressType] = useState('');
  const navigate = useNavigate();
  const isMdUp = useMediaQuery((theme) => theme.breakpoints.up('md'));

  useEffect(() => {
    const selectedDeliveryAddress = localStorage.getItem("selectedAddress");
    if (selectedDeliveryAddress) {
      setAddress(JSON.parse(selectedDeliveryAddress));
    }
    setSelectedDeliveryAddressType(localStorage.getItem('selectedDeliveryAddressType'));
  }, [addressUpdated]);

  const toggleSavedAddressDrawer = (open) => (event) => {
    setSavedAddressDrawer(open);
  };

  const handleChangeAddress = () => {
    if (isMdUp) {
      setSavedAddressDrawer(true);
    } else {
      navigate('/deliveryScreen');
    }
  };

  return (
    <>
    {selectedDeliveryAddressType === 'EXISTING' ? (
      <Box className="deliveryAddressComponent">
          <Box className="firstBox">
              <Box sx={{ display: 'flex' }}>
                  <DeliveryDiningOutlined fontSize={"large"} className="DeliveryIcon" alt="pickUpIcon" />
                  <Typography className="deliveryText">Delivery Details</Typography>
              </Box>
              <Typography onClick={handleChangeAddress} className='changeBtn'>Change</Typography>
          </Box>
          <Box className="secondBox">
              <Typography className="contactDetails">{address.receiverName}</Typography>
              <Typography className="contactDetails">{address.receiverContact}</Typography>
              <Typography className="address">{address.addressLine1}, {address.addressLine2}, {address.landmark}, {address.city}, {address.pincode}</Typography>
          </Box>
      </Box>
      ) : (
        null
      )}
      <Drawer
        anchor="left"
        open={savedAddressDrawer}
        onClose={toggleSavedAddressDrawer(false)}
        classes={{ paper: "drawerPaperForSavedAddress" }}
      >
        <Box onClick={(event) => event.stopPropagation()}>
          <DeliveryScreen />
        </Box>
      </Drawer>
    </>
  );
};

export default DeliveryAddressComponent;
