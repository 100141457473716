import React from 'react';
import { Box, Grid, Hidden } from '@mui/material';
import { ShimmerCategoryItem, ShimmerSocialPost, ShimmerCategoryList, ShimmerSimpleGallery, ShimmerThumbnail, ShimmerContentBlock } from "react-shimmer-effects";

const ProductListingSkeleton = () => {
  const skeletonItems = Array.from({ length: 6 }); // Adjust length as needed for the number of skeletons

  return (
    <Box className="products-listing">
      {/* Category List Skeleton */}
      <Box className="category-list">
        {skeletonItems.map((_, index) => (
          <ShimmerCategoryItem key={index} title />
        ))}
      </Box>

      {/* Products Container Skeleton */}
      <Box className="products-container">
        <Hidden mdDown>
          <Grid container >
            {skeletonItems.map((_, categoryIndex) => (
              <Grid container key={categoryIndex} spacing={2}>
                <Grid item xs={10} md={12} sx={{ margin: '60px 0 0 20px'}}>
                    <ShimmerThumbnail height={150} rounded />
                    <ShimmerSimpleGallery imageType="circular" imageHeight={200} caption />
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid container >
            {skeletonItems.map((_, categoryIndex) => (
              <Grid container key={categoryIndex} spacing={2}>
                <Grid item xs={10} md={12} sx={{ margin: '60px 0 0 15px'}}>
                    <ShimmerThumbnail height={90} rounded />
                    <ShimmerCategoryItem hasImage imageType="circular" imageWidth={100} imageHeight={100} text />
                    <ShimmerCategoryItem hasImage imageType="circular" imageWidth={100} imageHeight={100} text />
                    <ShimmerCategoryItem hasImage imageType="circular" imageWidth={100} imageHeight={100} text />
                    <ShimmerCategoryItem hasImage imageType="circular" imageWidth={100} imageHeight={100} text />
                    <ShimmerCategoryItem hasImage imageType="circular" imageWidth={100} imageHeight={100} text />
                    <ShimmerCategoryItem hasImage imageType="circular" imageWidth={100} imageHeight={100} text />
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Hidden>
      </Box>
    </Box>
  );
};

export default ProductListingSkeleton;
