import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import Images from '../../utils/Images';
import { useNavigate } from "react-router-dom";

const QRForwardScreen = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // navigate("https://r8rpc.test-app.link/");
        setTimeout(() => {
            window.location.href = "https://r8rpc-alternate.app.link";
        }, 2000)
    }, [navigate]);

    return (
        <Box sx={{ backgroundColor: '#1E9CED', width: 'auto', minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img
                src={Images.xpanseSplashLogo}
                style={{
                    width: 'clamp(180px, 30vw, 400px)',
                  }}
                alt="Xpanse Logo"
            />
        </Box>
    )
}

export default QRForwardScreen;
