import React from 'react';
import { Box, Typography } from '@mui/material';

function AboutUs() {
  return (
    <div className='privacyScreen'>
        <Typography className='privacyTitle'>ABOUT US</Typography>
        <Box className='privacyPolicyContainer'>
            <div>
                <Typography className='lastUpdatedText'>LAST UPDATED: 20-09-2024</Typography>
                {/* <p className='privacyPolicyDesc'>In these terms, references to "you," "yourself," "your" shall refer to the user of the Xpanse app as defined herein. The terms "we," "us," "our," "Company" shall refer to TEAM24 RETAIL TECH PRIVATE LIMITED, with its registered address at House No. 13/279/A70 T-11, Shed No Silvio Heights, Panaji, Tiswadi, North Goa - 403001, Goa, which holds the ownership rights to Xpanse, the brand, and the Xpanse Mobile App, the official food ordering app of Xpanse Café.</p> */}
                <p className='privacyPolicyDesc'>At Xpanse, we are more than just a coffee shop—we’re a destination for creativity, exploration, and community. Our mission is to inspire the unique creative spirit in everyone, helping individuals tap into their hidden potential. Our Arctic tern logo represents this spirit of endurance and discovery, embodying our commitment to offering an innovative and refreshing approach to coffee culture.</p>
                <p className='privacyPolicyDesc'>We’re pioneering a new coffee experience in India with our fully app-based ordering system, making Xpanse India’s first customer-centric, tech-powered cafe. Orders are placed exclusively through the Xpanse app, with notifications when your order is ready for pick-up, creating a seamless, convenient, and contactless experience. Without the need for counter staff or baristas, our fully automated, precision-driven coffee machines craft each cup to perfection, bringing premium quality in every sip.</p>
                <p className='privacyPolicyDesc'>Our streamlined operations enable us to serve exceptional coffee and food without the high overhead costs of traditional cafes, making premium coffee accessible to everyone, every day. Xpanse is built on a tech-driven model that leverages customer insights for personalized service and efficiency, ensuring we can offer great coffee at an affordable price point.</p>
                <p className='privacyPolicyDesc'>Our menu features a delightful range of classic coffee roasts, specialty drinks, frappes, pizzas, and freshly prepared snacks and meals—all carefully curated to pair perfectly with your coffee. We hope every visit to Xpanse Cafe sparks inspiration, creativity, and enjoyment in every sip and bite.</p>
            </div>
        </Box>
    </div>
  );
}

export default AboutUs;
