import { Home, LocationOnOutlined, MyLocation, WorkRounded } from '@mui/icons-material';
import { Box, Button, Drawer, Grid, TextField, Typography, useMediaQuery } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { API } from '../../../utils/Api';
import * as Yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import AddAddressMapScreen from './AddAddressMapScreen';

const AddAddressScreen = ({ addressToAdd }) => {
    const theme = useTheme();
    const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
    const location = useLocation();
    const navigate = useNavigate();
    const token = localStorage.getItem("token");
    const [activeChip, setActiveChip] = useState('HOME');
    const [showAddressNameField, setShowAddressNameField] = useState(false);
    const address = location.state || {};
    const [addressToAddPicked, setAddressToAddPicked] = useState(addressToAdd);
    const [addAddressMapScreen, setAddAddressMapScreen] = useState(false);

    console.log(addressToAddPicked);

    const validationSchema = Yup.object().shape({
        flat: Yup.string().required('Flat / H. No. / Floor No. / Building Name is required').matches(/^[A-Za-z0-9][A-Za-z0-9 ,.'-]*$/, 'Cannot contain a special character').min(2, 'Must be at least 2 characters').trim(),
        street: Yup.string().required('Street Address is required').matches(/^[A-Za-z0-9][A-Za-z0-9 ,.'-]*$/, 'Cannot contain a special character').min(2, 'Must be at least 2 characters').trim(),
        receiverName: Yup.string().required('Name is required').matches(/^[A-Za-z][A-Za-z'-]* ?([A-Za-z'-]+ ?)*$/, 'Name must contain only letters, spaces, hyphens, and apostrophes').min(2, 'Name must be at least 2 characters').trim(),
        receiverContact: Yup.string().required("Receiver's Contact is required").matches(/^[6789][0-9]{9}$/, 'Must be a 10 digit number starting with 6, 7, 8, or 9').trim(),
        addressName: showAddressNameField ? Yup.string().required('Address Name is required').matches(/^[A-Za-z0-9][A-Za-z0-9 ,.'-]*$/, 'Cannot contain a special character').min(2, 'Must be at least 2 characters').trim() : Yup.string(),
    });

    const initialValues = {
        flat: '',
        street: '',
        landmark: '',
        receiverName: '',
        receiverContact: '',
        addressName: ''
    };

    const handleChipClick = (chipName) => {
        setActiveChip(chipName);
        console.log(chipName);
        if (chipName === 'OTHER') {
        setShowAddressNameField(true);
        } else {
        setShowAddressNameField(false);
        }
    };
    
    const handleSubmit = async (values, { setSubmitting }) => {
        console.log('Submitted values:', values);
        setSubmitting(false);
        try {
        const requestBody = {
            type: activeChip,
            addressLine1: values.flat,
            addressLine2: values.street,
            // landmark: values.landmark,
            receiverName: values.receiverName,
            receiverContact: values.receiverContact,
            pincode: address?.address?.address?.postcode ||  addressToAddPicked?.address?.postcode,
            city: address?.address?.address?.suburb || address?.address?.address?.village || address?.address?.address?.town || address?.address?.address?.county || addressToAddPicked?.address?.suburb || addressToAddPicked?.address?.village || addressToAddPicked?.address?.town || addressToAddPicked?.address?.county,
            state: address?.address?.address?.state || addressToAddPicked?.address?.state,
            country: "India",
            coordinates: {                     
                latitude: address?.address?.lat || addressToAddPicked?.lat,
                longitude: address?.address?.lon || addressToAddPicked?.lon
            }
        };
        if (values.landmark) {
            requestBody.landmark = values.landmark;
        }
        if (activeChip === "OTHER") {
            requestBody.addressName = values.addressName;
        }
        const response = await axios.post(`${API.BASE_URL}delivery-details`, requestBody, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        console.log('Address added successfully:', response.data.data.dbResponse);
        toast.success("Address added Successfully");
        if (isMdUp) {
            // navigate('/');
            window.location.reload();
        } else {
            navigate('/savedAddressesScreen');
        }
        } catch (error) {
        console.error('Error adding address:', error);
        toast.error(error.response.data.error);
        }
    };

    const handleEditAddress = () => {
        if (isMdUp) {
            setAddAddressMapScreen(true);
        } else {
            navigate('/addAddressMapScreen');
        }
    };

    const toggleAddAddressMapScreen = (open) => (event) => {
        setAddAddressMapScreen(open);
    };

    return (
        <Box>
            <Box className="addressModal">
                <Grid container className="address-container">
                    <Grid item xs={9}>
                        <Typography className="address">
                        <MyLocation sx={{ paddingRight: '5px' }} />
                            {address?.address?.address?.suburb || address?.address?.address?.village || address?.address?.address?.town || address?.address?.address?.county || addressToAddPicked?.address?.suburb || addressToAddPicked?.address?.village || addressToAddPicked?.address?.town || addressToAddPicked?.address?.county}
                        </Typography>
                        <Typography className="address2">
                            { address?.address?.state_district || addressToAddPicked?.address?.state_district}, {address?.address?.address?.state || addressToAddPicked?.address?.state}, {address?.address?.address?.postcode || addressToAddPicked?.address?.postcode}.
                        </Typography>
                    </Grid>
                    {/* <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center', marginRight: '0px', cursor: 'pointer' }}>
                        <Box onClick={handleEditAddress} className="grantButton">Edit</Box>
                    </Grid> */}
                </Grid>
                <Box>
                <Typography className="enterDetailsTitle">Enter Address Details</Typography>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ values, errors, touched, handleChange, handleBlur, isSubmitting }) => (
                    <Form className="formBox">
                        <TextField
                            name="flat"
                            className="textField"
                            fullWidth
                            placeholder="Enter"
                            label="Flat / H. No. / Floor No. / Building Name"
                            value={values.flat}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        {errors.flat && touched.flat && <div className="errorText">{errors.flat}</div>}
                        <TextField
                            name="street"
                            className="textField"
                            fullWidth
                            placeholder="Enter"
                            label="Street Address"
                            value={values.street}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        {errors.street && touched.street && <div className="errorText">{errors.street}</div>}
                        <TextField
                            name="landmark"
                            className="textField"
                            fullWidth
                            placeholder="Enter"
                            label="Landmark (Optional)"
                            value={values.landmark}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        <TextField
                            name="receiverName"
                            className="textField"
                            fullWidth
                            placeholder="Enter"
                            label="Receiver's Name"
                            value={values.receiverName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        {errors.receiverName && touched.receiverName && <div className="errorText">{errors.receiverName}</div>}
                        <TextField
                            name="receiverContact"
                            className="textField"
                            fullWidth
                            placeholder="Enter"
                            label="Receiver's Contact"
                            value={values.receiverContact}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        {errors.receiverContact && touched.receiverContact && <div className="errorText">{errors.receiverContact}</div>}
                        <Grid container justifyContent="space-around">
                        <Grid item xs={3}>
                            <Box
                                className={`addressChip ${activeChip === 'HOME' ? 'selected' : ''}`}
                                onClick={() => handleChipClick('HOME')}
                            >
                                <Home sx={{ paddingRight: '4px' }}/> Home
                            </Box>
                        </Grid>
                        <Grid item xs={3}>
                            <Box
                                className={`addressChip ${activeChip === 'WORK' ? 'selected' : ''}`}
                                onClick={() => handleChipClick('WORK')}
                            >
                                <WorkRounded sx={{ paddingRight: '4px' }}/> Work
                            </Box>
                        </Grid>
                        <Grid item xs={3}>
                            <Box
                                className={`addressChip ${activeChip === 'OTHER' ? 'selected' : ''}`}
                                onClick={() => handleChipClick('OTHER')}
                            >
                            <LocationOnOutlined sx={{ paddingRight: '4px' }}/> Other
                            </Box>
                        </Grid>
                        </Grid>
                        {showAddressNameField && (
                        <>
                        <TextField
                            name="addressName"
                            className="textField"
                            fullWidth
                            placeholder="Enter"
                            label="Address Name"
                            value={values.addressName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        {errors.addressName && touched.addressName && <div className="errorText">{errors.addressName}</div>}
                        </>
                        )}
                        <Button type="submit" className="button" disabled={isSubmitting}>
                            {isSubmitting ? 'Submitting...' : 'Continue'}
                        </Button>
                    </Form>
                    )}
                </Formik>
                </Box>
            </Box>
            <Drawer
                anchor="right"
                open={addAddressMapScreen}
                onClose={toggleAddAddressMapScreen(false)}
                classes={{ paper: "drawerPaperForEditProfile" }}
            >
                <Box className="addressAddMapScreen" onClick={(event) => event.stopPropagation()}   sx={{ }}>
                    <AddAddressMapScreen />
                </Box>
            </Drawer>
        </Box>
    )
}

export default AddAddressScreen;
