// import { Box, Typography, TextField, Grid, InputAdornment } from '@mui/material';
// import React, { useState } from 'react';
// import * as Yup from 'yup';
// import PrimaryButton from '../../components/PrimaryButton';
// import axios from 'axios';
// import { toast } from 'react-toastify';
// import { API } from '../../utils/Api';
// import { useNavigate } from 'react-router-dom';
// import StoriesComponent from './../../components/StoriesComponent';

// function MobileNumber() {
//   const [btnDisabled, setBtnDisabled] = useState(false);
//   const [number, setNumber] = useState('');
//   const [errors, setErrors] = useState({});
//   const navigate = useNavigate();

//   const schema = Yup.object().shape({
//     number: Yup.string()
//       .matches(/^[0-9]{10}$/, 'Must be exactly 10 digits')
//       .required('Mobile Number is required'),
//   });

//   const validateField = async (field, value) => {
//     try {
//       await schema.validateAt(field, { number: value });
//       setErrors({ ...errors, [field]: '' });
//     } catch (error) {
//       if (error instanceof Yup.ValidationError) {
//         setErrors({ ...errors, [field]: error.message });
//       }
//     }
//   };

//   const handleInputChange = (field, value) => {
//     if (field === 'number') {
//       setNumber(value);
//       validateField('number', value);
//     }
//   };

//   const handleButtonClick = async () => {
//     const isValid = await schema.isValid({ number });
//     console.log(number);
//     if (isValid) {
//       try {
//         const response = await axios.post(`${API.BASE_URL}auth/user/send-otp`, {
//           "mobile": number,
//         });
//         if (response.data.status === true) {
//           console.log('OTP sent successfully:', response.data);
//           toast.success('OTP sent successfully!', {
//             autoClose: 3000,
//           });
//           navigate('/verifyOtp', { state: { mobile: number, authType: response.data.data.authType } });
//         }
//       } catch (error) {
//         console.error('Error:', error.response.data.data.message);
//         let errorMessage = error.response.data.data.message;
//         toast.error(errorMessage, {
//           autoClose: 3000,
//         });
//       } finally {
//       }
//     }
//   };
  
//   return (
//     <>
//       <Box sx={{ textAlign: 'left', padding: '0px', margin: '0px' }}>
//         <Grid item md={3} sx={{ padding: '10px', margin: '0px' }}>
//           <Typography sx={{ fontWeight: '800', fontSize: '30px', margin: '21px 0 31px 0', lineHeight: '1.2' }}>
//             Enter your mobile number <br /> to get OTP
//           </Typography>
//           <Box sx={{ textAlign: 'left' }}>
//             <TextField
//               fullWidth
//               id="number"
//               sx={{
//                 borderRadius: '8px',
//                 backgroundColor: '#fff',
//                 '& .MuiOutlinedInput-root': {
//                   '& fieldset': {
//                     borderColor: '#1DA1F2',
//                   },
//                   '&:hover fieldset': {
//                     borderColor: 'cornflowerblue',
//                   },
//                   '&.Mui-focused fieldset': {
//                     borderColor: '#1DA1F2',
//                   },
//                 },
//               }}
//               placeholder="10 digit mobile number"
//               label="Mobile Number"
//               variant="outlined"
//               value={number}
//               onChange={(e) => handleInputChange('number', e.target.value)}
//               onBlur={() => validateField('number', number)}
//               InputLabelProps={{ style: { color: '#2196F3' } }}
//               error={!!errors.number}
//               InputProps={{
//                 startAdornment: (
//                   <InputAdornment position="start">
//                     <Box sx={{ display: 'flex', borderRight: '1px solid #474747', paddingRight: '8px' }}>
//                       <span>+91</span>
//                     </Box>
//                   </InputAdornment>
//                 ),
//                 style: { borderColor: '#2196F3' },
//               }}
//             />
//             {errors.number && (
//               <Typography className="errorText" color="error">
//                 {errors.number}
//               </Typography>
//             )}
//           </Box>
//           <Grid container justifyContent={'center'} style={{ margin: '27px 0 27px 0' }}>
//             <Grid item xs={12} sm={12} md={12}>
//               <PrimaryButton
//                 title={'Get OTP'}
//                 onClick={handleButtonClick}
//                 disabled={btnDisabled}
//                 style={{ borderRadius: '30px', fontSize: '18px' }}
//               />
//             </Grid>
//           </Grid>
//           <Typography style={{ fontSize: '13px' }}>
//             By clicking, I accept the <span style={{ textDecoration: 'underline' }}>terms of service</span> &{' '}
//             <span style={{ textDecoration: 'underline' }}>privacy policy</span>
//           </Typography>
//         </Grid>
//       </Box>
//     </>
//   );
// }

// export default MobileNumber;

import { Box, Typography, TextField, Grid } from '@mui/material';
import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import PrimaryButton from '../../components/PrimaryButton';
import { ArrowBack } from '@mui/icons-material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API } from '../../utils/Api';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { setUser } from '../../store/Profile/UserSlice';

function VerifyOtp() {
  const [otp, setOtp] = useState('');
  const [errors, setErrors] = useState({});
  const [countdown, setCountdown] = useState(59);
  const location = useLocation();
  const navigate =  useNavigate();
  const mobile = location.state?.mobile || '';
  const authType = location.state?.authType || '';
  const dispatch = useDispatch();

  useEffect(() => {
    let interval;

    if (countdown > 0) {
      interval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [countdown]);

  const handleResendOtp = async () => {
    try {
      const response = await axios.post(`${API.BASE_URL}auth/user/send-otp`, {
        "mobile": mobile,
      });
      if (response.data.status === true) {
        toast.success('OTP resent successfully', {
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.error('Error:', error.response.data.data.message);
      let errorMessage = error.response.data.data.message;
      toast.error(errorMessage, {
        autoClose: 3000,
      });
    } finally {
    }
  };

  const schema = Yup.object().shape({
    otp: Yup.string()
      .matches(/^[0-9]{5}$/, 'Must be exactly 5 digits')
      .required('OTP is required'),
  });

  const validateField = async (field, value) => {
    try {
      await schema.validate({ [field]: value });
      setErrors({ ...errors, [field]: '' });
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        setErrors({ ...errors, [field]: error.message });
      }
    }
  };

  const handleInputChange = (field, value) => {
    setOtp(value);
    setErrors({ ...errors, [field]: '' });
  };

  const handleButtonClick = () => {
    if (authType === 'LOGIN') {
      axios.post(`${API.BASE_URL}auth/user/verify-otp`, {
        "mobile": mobile,
        "otp": otp,
        "isSignup": false
      })
      .then(response => {
        console.log('Login API Response:', response.data);
        navigate('/locationScreen');
        localStorage.setItem('token', response.data.data.jwt);
        dispatch(setUser(response.data.data.user));
        toast.success('Logged in successfully!', {
          autoClose: 3000,
        });
      })
      .catch(error => {
        console.error('Error:', error.response.data.data.message);
        let errorMessage = error.response.data.data.message;
        toast.error(errorMessage, {
          autoClose: 3000,
        });
      });
    }
    else {
      axios.post(`${API.BASE_URL}auth/user/verify-otp`, {
        "mobile": mobile,
        "otp": otp,
        "isSignup": true
      })
      .then(response => {
        console.log('Login API Response:', response.data);
        toast.success('OTP verified successfully!', {
          autoClose: 3000,
        });
        navigate('/accountDetails', { state: { mobile: mobile } });
      })
      .catch(error => {
        console.error('Error:', error.response.data.data.message);
        let errorMessage = error.response.data.data.message;
        toast.error(errorMessage, {
          autoClose: 3000,
        });
      });
    }
  };

  return (
    <>
      <Box sx={{ textAlign: 'left', padding: '0px', margin: '0px' }}>
        <Link to="/">
          <ArrowBack sx={{ margin: '16px 0 0 5px', fontSize: '30px' }} />
        </Link>
        <Grid item md={3} sx={{ padding: '10px', margin: '0px' }}>
          <Typography sx={{ fontWeight: '800', fontSize: '30px', margin: '5px 0 31px 0', lineHeight: '1.2' }}>
            Verify with OTP sent to <br /> {mobile}
          </Typography>
          <Box sx={{ textAlign: 'left' }}>
            <TextField
              fullWidth
              id="otp"
              sx={{
                borderRadius: '8px',
                backgroundColor: '#fff',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#1DA1F2',
                  },
                  '&:hover fieldset': {
                    borderColor: 'cornflowerblue',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#1DA1F2',
                  },
                },
              }}
              placeholder="Enter OTP"
              variant="outlined"
              value={otp}
              onChange={(e) => handleInputChange('otp', e.target.value)}
              onBlur={() => validateField('otp', otp)}
              inputProps={{ style: { textAlign: 'center' } }}
              InputLabelProps={{ style: { color: '#2196F3' } }}
              error={!!errors.otp}
            />
            {errors.otp && (
              <Typography className="errorText" color="error">
                {errors.otp}
              </Typography>
            )}
          </Box>
          <Grid container justifyContent={'center'} style={{ margin: '27px 0 27px 0' }}>
            <Grid item xs={12} sm={12} md={12}>
              <PrimaryButton
                title={`Continue`}
                onClick={handleButtonClick}
                style={{ borderRadius: '30px', fontSize: '18px' }}
              />
            </Grid>
          </Grid>
          <Typography style={{ fontSize: '16px' }}>
            {countdown <= 0 ? (
                <span
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={() => {
                      handleResendOtp();
                      setCountdown(59);
                    }}
                >
                    Resend OTP
                </span>
                ) : (
                <span>
                    Resend OTP in
                <span
                style={{
                    textDecoration: 'underline',
                    color: '#1DA1F2',
                    cursor: 'pointer',
                    marginLeft: '5px'
                }}
                >
                    {countdown < 10 ? `00:0${countdown}` : `00:${countdown}`}
                </span>
                </span>
            )}
          </Typography>
        </Grid>
      </Box>
    </>
  );
}

export default VerifyOtp;