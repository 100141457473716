import { Box, Typography } from '@mui/material';
import React from 'react';

const RewardsScreen = () => {
  return (
    <Box className="rewardScreen">
        <Typography>sduhfuiashdfvuighasfd</Typography>
    </Box>
  )
}

export default RewardsScreen;
