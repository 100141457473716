import { Box } from '@mui/material';
import React from 'react';
import { ShimmerThumbnail } from "react-shimmer-effects";

const OutletSkeleton = () => {
  return (
    <Box>
      <ShimmerThumbnail height={180} rounded />
      <ShimmerThumbnail height={180} rounded />
      <ShimmerThumbnail height={180} rounded />
    </Box>
  )
}

export default OutletSkeleton;
