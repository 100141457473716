import React, { useState, useEffect } from "react";
import { TextField, InputAdornment, Box, Typography, Modal, Button, Autocomplete, Grid, Drawer, useMediaQuery, Hidden, Dialog, DialogContent, DialogContentText, DialogTitle, DialogActions } from "@mui/material";
import { LoadScript, GoogleMap, MarkerF } from "@react-google-maps/api";
import { DeleteOutline, HomeRounded, LocationOnOutlined, MyLocation, Place, SearchSharp, WorkRounded } from "@mui/icons-material";
import Images from "../../utils/Images";
import axios from "axios";
import { API, GOOGLE_MAP } from "../../utils/Api";
import { getPreciseDistance } from 'geolib';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setPickedAddress } from '../../store/Address/addressSlice';
import DeliveryOutletScreen from "./DeliveryScreens/DeliveryOutletScreen";
import toast from "react-hot-toast";
import PlacesAutocomplete from "../../components/PlacesComponent";

const DeliveryScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [map, setMap] = useState(null);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [locationDrawer, setLocationDrawer] = useState(true);
  const [cities, setCities] = useState([]);
  const token = localStorage.getItem("token");
  const [selectedCity, setSelectedCity] = useState("");
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [selectedCityData, setSelectedCityData] = useState(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [selectedStore, setSelectedStore] = useState(null);
  const [address, setAddress ] = useState([]);
  const [markerPosition, setMarkerPosition] = useState({ lat: 20.5937, lng: 78.9629 });
  const [zoom, setZoom] = useState(5);
  const [savedUserAddressList, setSavedUserAddressList] = useState([]);
  const [isPermissionGranted, setIsPermissionGranted] = useState(false);
  const matchesMdUp = useMediaQuery(theme => theme.breakpoints.up('md'));
  const [drawerForDeliveryOutlet, setDrawerForDeliveryOutlet] = useState(false);
  const [addressModal, setAddressModal] = useState(false);
  const [addressToDelete, setAddressToDelete] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleCoordinatesChange = async (newCoordinates) => {
    setZoom(5);
    setMarkerPosition(newCoordinates);
    console.log(newCoordinates);
    try {
      const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${newCoordinates.lat}&lon=${newCoordinates.lng}`;
      const response = await fetch(url);
      const data = await response.json();
      setAddress(data);
      dispatch(setPickedAddress(data));
    } catch (error) {
      console.error("Error getting current location:", error);
    } finally {
      setLoading(false);
    }
    if (map) {
      map.panTo(newCoordinates);
      let currentZoom = map.getZoom();
      const targetZoom = 14;
      const zoomInterval = setInterval(() => {
        if (currentZoom < targetZoom) {
          currentZoom += 1;
          map.setZoom(currentZoom);
        } else {
          clearInterval(zoomInterval);
        }
      }, 200);
    }
  };

  const containerStyle = {
    width: "100%",
    height: matchesMdUp ? "55vh" : "75vh",
  };

  const onLoad = (map) => {
    setMap(map);
    setMapLoaded(true);
    setMarkerPosition({ lat: 20.5937, lng: 78.9629 });
  };

  const fetchLocation = async () => {
    try {
      handleCloseDrawer();
      const position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });
      const { latitude, longitude } = position.coords;
      setLatitude(latitude);
      setLongitude(longitude);
      setMarkerPosition({ lat: latitude, lng: longitude });
      localStorage.setItem("deliveryLatitude", latitude);
      localStorage.setItem("deliveryLongitude", longitude);
      if (map) {
        map.panTo({ lat: latitude, lng: longitude });
        let currentZoom = map.getZoom();
        const targetZoom = 14;
        const zoomInterval = setInterval(() => {
          if (currentZoom < targetZoom) {
            currentZoom += 1;
            map.setZoom(currentZoom);
          } else {
            clearInterval(zoomInterval);
          }
        }, 200);
      }
      const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;
      const response = await fetch(url);
      const data = await response.json();
      setAddress(data);
      dispatch(setPickedAddress(data));
      localStorage.setItem("pickedAddress", JSON.stringify(data));
    } catch (error) {
      console.error("Error getting current location:", error);
      if (error.code === error.PERMISSION_DENIED) {
        console.log('Enable location permission');
        const isPermissionGranted = window.confirm("Location permission is required. Do you want to allow it?");
        if (isPermissionGranted) {
          fetchLocation();
        } else {
          window.prompt("Location permission is required to proceed.");
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const handleAddress = () => {
    // navigate('/locationScreen');
    if (matchesMdUp) {
      setDrawerForDeliveryOutlet(true);
    } else {
      navigate('/deliveryOutletScreen');
    }
    localStorage.setItem('selectedDeliveryAddressType', 'NEW');
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleOpenDrawer = () => {
    setLocationDrawer(true);
  };

  const handleCloseDrawer = () => {
    setLocationDrawer(false);
  };

  const handleCloseDrawerForDeliveryOutlet = () => {
    const selectedStoreData = localStorage.getItem('selectedStoreData');
    if (!selectedStoreData) {
      return;
    }
    setDrawerForDeliveryOutlet(false);
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleMarkerDragEnd = async (event) => {
    const newLat = event.latLng.lat();
    const newLng = event.latLng.lng();
    setMarkerPosition({ lat: newLat, lng: newLng });
    setLatitude(newLat);
    setLongitude(newLng);
    localStorage.setItem("deliveryLatitude", newLat);
    localStorage.setItem("deliveryLongitude", newLng);
    const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${newLat}&lon=${newLng}`;
    const response = await fetch(url);
    const data = await response.json();
    console.log('====================================');
    console.log(data);
    console.log('====================================');
    setAddress(data);
    localStorage.setItem("pickedAddress", JSON.stringify(data));
  };

  const fetchUserAddresses= async () => {
    try {
      const response = await axios.get(`${API.BASE_URL}delivery-details/user`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('Saved user address', response?.data);
      setSavedUserAddressList(response?.data?.data?.deliveryDetails);
    } catch (error) {
      console.log('Saved user address Error', error?.response?.data);
    }
  };

  useEffect(() => {
    fetchUserAddresses();
    navigator.permissions.query({ name: 'geolocation' }).then((result) => {
      if (result.state === 'granted') {
        setIsPermissionGranted(true);
        // fetchLocation();
      } else if (result.state === 'prompt') {
        setIsPermissionGranted(false);
      } else if (result.state === 'denied') {
        setIsPermissionGranted(false);
      }
    });
  },[]);

  const handleSelectedAddressClick = (address) => {
    localStorage.setItem('selectedAddress', JSON.stringify(address));
    localStorage.setItem('selectedDeliveryAddressType', 'EXISTING');
    if (matchesMdUp) {
      // navigate('/');
      setLocationDrawer(true);
      setDrawerForDeliveryOutlet(true);
    } else {
      navigate('/deliveryOutletScreen');
    }
    console.log('Selected address:', address);
    setAddressModal(false);
    handleDialogClose();
    toast.success("Address selected successfully");
    // window.location.reload();
  };

  const handleAddressModalOpen = () => {
    setAddressModal(true);
  };

  const handleAddressModalClose = () => {
    setAddressModal(false);
  };

  const handleDeleteClick = (addressId) => {
    setAddressToDelete(addressId);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setAddressToDelete(null);
  };
  
  const handleViewAddress = () => {
    if (matchesMdUp) {
      handleAddressModalOpen();
    } else {
      console.log('kashndfj');
    }
  }

  const deleteAddress= async (addressId) => {
    try {
      const response = await axios.delete(`${API.BASE_URL}delivery-details`,{
        data: { "_id": addressId },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('Address deleted successfully', response?.data);
      toast.success("Address deleted successfully");
      fetchUserAddresses();
    } catch (error) {
      console.log('Delete address Error', error?.response?.data);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmDelete = () => {
    if (addressToDelete) {
      deleteAddress(addressToDelete);
    }
    handleDialogClose();
  };

  return (
    <LoadScript googleMapsApiKey={GOOGLE_MAP.GOOGLE_MAP_KEY} loadingElement={
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          flexDirection: 'column',
          textAlign: 'center',
          backgroundColor: '#eaeaea'
        }}
      >
        <LocationOnOutlined sx={{ fontSize: 50}} />
        <Typography variant="h6">Picking Up <br/>Location</Typography>
      </Box>
    }>
      <>
      <Box className="searchCityBox" sx={{ left: "12%" }}>
        <PlacesAutocomplete onCoordinatesChange={handleCoordinatesChange} />
      </Box>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={markerPosition}
        zoom={zoom}
        onLoad={onLoad}
        options={{ mapTypeControl: false, zoomControl: false, fullscreenControl: false, keyboardShortcuts: false, streetViewControl: false }}
      >
        {markerPosition.lat && markerPosition.lng && mapLoaded && (
          <MarkerF
            position={markerPosition}
            draggable={true}
            onDragEnd={handleMarkerDragEnd}
            icon={Images.xpanseDeliveryPin}
          />
        )}
      </GoogleMap>
      <Box className="locateMeButton" onClick={fetchLocation}> <Place />  Locate Me</Box>
      {address && address.address ? (
        <Grid container className="address-container">
          <Grid item xs={9}>
            <Typography className="address">
              <MyLocation sx={{ paddingRight: '5px' }} />
              {address.address.suburb || address.address.village || address.address.town || address.address.county}
            </Typography>
            <Typography className="address2">
              {address.address.state_district}, {address.address.state}, {address.address.postcode}.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box className="button" sx={{ backgroundColor: 'red', cursor: 'pointer' }} onClick={handleAddress}>Continue</Box>
          </Grid>
        </Grid>
      ) : (
        <Grid container className="address-container" sx={{ padding: '30px', textAlign: 'center' }}>
          <Typography variant="body1" sx={{ textAlign: 'center', color: '#186B8C', fontWeight: '700' }}>Move the pin to your location</Typography>
        </Grid>
      )}
      <Drawer 
        open={locationDrawer}
        onClose={handleCloseDrawer}
        anchor="bottom"
      >
        {isPermissionGranted ? null : (
          <Box sx={{ backgroundColor: '#F9F0E7', borderTopLeftRadius: '12px', borderTopRightRadius: '12px' }}>
            <Grid container justifyContent={'space-evenly'}>
              <Grid>
                <MyLocation className="locationIcon" />
              </Grid>
              <Grid className="permissionTitle">
                Location Permission Required
              </Grid>
              <Grid sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                <Box className="grantButton" onClick={fetchLocation}>Grant</Box>
              </Grid>
              <Grid>
                <Typography className="titleDesc">Granting Location permission will ensure accurate address and hassle free delivery</Typography>
              </Grid>
            </Grid>
          </Box>
        )}
        {savedUserAddressList.length > 0 && (
          <Box className="addressSelectBox">
            <Typography sx={{ fontWeight: '700' }}>Select Delivery Address</Typography>
            <Typography onClick={handleViewAddress} sx={{ color: '#1E9CED', textDecoration: 'underline', fontWeight: '700', cursor: 'pointer' }}>VIEW ALL</Typography>
          </Box>
        )}
        {savedUserAddressList.length > 0 && savedUserAddressList.slice(0, 2).map((address, index) => (
          <Box key={address._id} onClick={() => handleSelectedAddressClick(address)} className="addressBox" sx={{ borderBottom: (savedUserAddressList.length === 1 || index === savedUserAddressList.length - 1) ? 'none' : '1px solid #c5c5c5', margin: '10px 20px 0px 20px', cursor: 'pointer' }}>
            <Box sx={{ display: 'flex', padding: '0 0 0 0px' }}>
              {address.type === 'HOME' && <HomeRounded sx={{ color: '#1E9CED' }} />}
              {address.type === 'WORK' && <WorkRounded sx={{ color: '#1E9CED' }} />}
              {address.type !== 'HOME' && address.type !== 'WORK' && <LocationOnOutlined sx={{ color: '#1E9CED' }} />}
              <Typography sx={{ fontWeight: '700', padding: '0 0 0 10px' }}>{address.receiverName}</Typography>
            </Box>
            <Typography sx={{ padding: '0 10px 15px 30px'}}>{address?.addressLine1}, {address?.addressLine2}, {address?.landmark}, {address?.state}, {address?.pincode}</Typography>
          </Box>
        ))}
        <Box onClick={handleCloseDrawer} sx={{ border: '1px solid #09A5F7', margin: '15px 15px 18px 15px', borderRadius: '12px', cursor: 'pointer' }}>
          <Hidden mdUp><Typography sx={{ color: '#BEBEBE', padding: '16px' }}>Enter Location</Typography></Hidden>
          <Hidden mdDown><Typography sx={{ color: '#BEBEBE', padding: '16px' }}>Select Different Location</Typography></Hidden>
        </Box>
      </Drawer>
      <Drawer
        anchor="left"
        open={drawerForDeliveryOutlet}
        onClose={handleCloseDrawerForDeliveryOutlet}
        PaperProps={{ 
          sx: { 
              height: '100%',
              overflow: 'auto',
              '&::-webkit-scrollbar': {
                display: 'none'
              },
              '-ms-overflow-style': 'none',
              'scrollbar-width': 'none'
          }
        }}
      >
        <Box sx={{ overflow: 'auto' }}>
          <DeliveryOutletScreen />
        </Box>
      </Drawer>
      </>
      <Modal open={modalOpen} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "white",
            boxShadow: 0,
            padding: '10px 30px 10px 30px',
            borderRadius: '12px',
            textAlign: 'center'
          }}
        >
          <Typography variant="h6" component="div" gutterBottom>
            No outlets found for your city.
          </Typography>
          <Button onClick={handleCloseModal}>Continue</Button>
        </Box>
      </Modal>
      <Dialog open={addressModal} onClose={handleAddressModalClose} fullWidth sx={{ '& .MuiPaper-root': { backgroundColor: '#DFEEF4', borderRadius: '16px' } }}>
        <Box className="savedAddressesScreen">
          {savedUserAddressList.map((address, index) => (
            <Box onClick={() => handleSelectedAddressClick(address)} key={address._id} className="addressBox" sx={{ borderBottom: (savedUserAddressList.length === 1 || index === savedUserAddressList.length - 1) ? 'none' : '1px solid #c5c5c5', margin: '10px 20px 0px 20px', cursor: 'pointer' }}>
              <Box sx={{ display: 'flex', padding: '0 0 0 0px' }}>
                {address.type === 'HOME' && <HomeRounded sx={{ color: '#1E9CED' }} />}
                {address.type === 'WORK' && <WorkRounded sx={{ color: '#1E9CED' }} />}
                {address.type !== 'HOME' && address.type !== 'WORK' && <LocationOnOutlined sx={{ color: '#1E9CED' }} />}
                <Typography sx={{ fontWeight: '700', padding: '0 0 0 10px' }}>{address.receiverName}</Typography>
              </Box>
              <Typography sx={{ padding: '0 10px 15px 30px' }}>{address?.addressLine1}, {address?.addressLine2}, {address?.landmark}, {address?.state}, {address?.pincode}</Typography>
            </Box>
          ))}
        </Box>
      </Dialog>
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
      >
        <DialogTitle>Confirm Address Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this address?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <style>
        {`
          body, html {
            margin: 0;
            padding: 0;
            background-color: #DFEEF4;
          }
        `}
      </style>
    </LoadScript>
  );
};

export default DeliveryScreen;
