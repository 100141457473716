import React, { useEffect, useState } from 'react'
import { API } from '../../../utils/Api';
import axios from 'axios';
import { Box, Button, Grid, Hidden, Typography, useMediaQuery } from '@mui/material';
import Images from '../../../utils/Images';
import { useNavigate } from 'react-router-dom';
import { KeyboardArrowLeft } from '@mui/icons-material';
import toast from 'react-hot-toast';
import OutletSkeleton from '../../../components/SkeletonComponents/OutletSkeleton';

const DeliveryOutletScreen = () => {
    const navigate = useNavigate();
    const [allOutlets, setAllOutlets] = useState([]);
    const [selectedStore, setSelectedStore] = useState([]);
    const [fetchingOutlets, setFetchingOutlets] = useState(false);
    const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'));
    const selectedDeliveryAddress = JSON.parse(localStorage.getItem("selectedAddress"));
    const latitude = localStorage.getItem("deliveryLatitude") || selectedDeliveryAddress?.coordinates?.latitude;
    const longitude = localStorage.getItem("deliveryLongitude") || selectedDeliveryAddress?.coordinates?.longitude;
    // console.log('selected address: ', selectedDeliveryAddress?.coordinates?.longitude);

    const getOutlets = async () => {
        setFetchingOutlets(true);
        try {
            const response = await axios.post(`${API.BASE_URL}outlet/getNearbyOutlets`,{
                "latitude": latitude,
                "longitude": longitude
            }, {
                // headers: {
                //   Authorization: `Bearer ${usertoken}`,
                // },
            })
            console.log('All delivery outlets fetched', response?.data, "Latitude", latitude, "Longitude", longitude);
            setAllOutlets(response?.data?.data?.outlets);
            setFetchingOutlets(false);
        } catch (error) {
            console.log("Error fetching all the outlets", error);
        } finally {
            setFetchingOutlets(false);
        }
    }

    const upcomingOutlets = [
        {
            cityName: "Mumbai",
            stateName: "Maharashtra"
        },
        {
            cityName: "Bengaluru",
            stateName: "Karnataka"
        }
    ];

    useEffect(() => {
        window.scrollTo(0, 0);
        getOutlets();
        if (selectedDeliveryAddress) {
        }
    }, []);

    const handleStoreClick = (outlet) => {
        localStorage.setItem('selectedStoreData', JSON.stringify(outlet));
        console.log('Selected store saved to local storage', outlet);
        toast.success("Outlet selected");
        if (isMdUp) {
            window.location.reload();
        } else {
            navigate('/productsListing');
        }
    };

    return (
        <Box className="pickupOutletScreen">
            {fetchingOutlets ? (
                <>
                    <Typography className="title" onClick={() => navigate('/advertiseScreen')}><Hidden mdUp><KeyboardArrowLeft /></Hidden> Select An Outlet Nearby</Typography>
                    <Box sx={{ margin: '8px 0 0 0'}}>
                        <OutletSkeleton />
                    </Box>        
                </>
            ) : (
                <>
                    {allOutlets?.length ===  0 ? (
                        <Typography className="title" onClick={() => navigate('/advertiseScreen')}><Hidden mdUp><KeyboardArrowLeft /></Hidden>No outlets found near your area.</Typography>
                    ) : (
                        <Typography className="title" onClick={() => navigate('/advertiseScreen')}><Hidden mdUp><KeyboardArrowLeft /></Hidden> Select An Outlet Nearby</Typography>
                    )}
                    {allOutlets?.map(outlet => (
                        <Grid className="pickupOutlet" key={outlet?._id} container>
                            <Grid item xs={5}>
                                <img style={{ borderRadius: '12px', width: '100%', height: '90%', objectFit: 'cover', filter: outlet?.unavailabilityMessage ? 'grayscale(100%)' : 'none' }} src={outlet?.outletImage} alt={outlet?.name} />
                            </Grid>
                            <Grid item xs={7} sx={{ paddingLeft: '6px' }}>
                                <Typography className="outletName">{outlet?.name}</Typography>
                                <Typography className="outletCity">{outlet?.city}, {outlet?.state}, {outlet?.country}</Typography>
                                <Typography className="outletAddress">{outlet?.address}</Typography>
                                <Typography className="outletAddress">{outlet?.pincode}</Typography>
                                {/* <Box className="openMapButton">Open Maps</Box> */}
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Box className="openMapButton" sx={{ marginLeft: 'auto', cursor: 'pointer' }} onClick={() => {
                                            const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&origin=${latitude},${longitude}&destination=${outlet?.coordinates?.latitude},${outlet?.coordinates?.longitude}`;
                                            window.open(googleMapsUrl, '_blank');
                                        }}>
                                        Open Maps
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                {outlet?.orderAcceptType === 'PICKUP' ? (
                                    <>
                                    <Box className="closedStoreText">
                                        <Typography variant='caption' sx={{ color: 'red' }}>This store is currently not accepting delivery orders.</Typography>
                                    </Box>
                                    <Box onClick={() => handleStoreClick(outlet)} sx={{ backgroundColor: '#fff'}} className="zomatoBtn">
                                        <img style={{ width: '10%' }} src={Images.zomatoLogo} />
                                    </Box>
                                    <Box onClick={() => handleStoreClick(outlet)} className="selectStoreBtn">
                                        <Typography variant='body1'>Swiggy</Typography>
                                    </Box>
                                    </>
                                ) : (
                                    outlet?.unavailabilityMessage ? (
                                        <Box className="closedStoreText">
                                            <Typography variant='caption' sx={{ color: 'red' }}>{outlet?.unavailabilityMessage}</Typography>
                                        </Box>
                                    ) : (
                                        <Box onClick={() => handleStoreClick(outlet)} className="selectStoreBtn">
                                            <Typography variant='body1'>Select Store</Typography>
                                        </Box>
                                    )
                                )}
                            </Grid>
                        </Grid>
                    ))}
                    {upcomingOutlets?.map(outlet =>(
                        <Grid className="pickupOutlet upcoming" container>
                            <Grid item xs={5}>
                                <img style={{ borderRadius: '12px', width: '100%', height: 'auto', objectFit: 'cover' }} src={Images.upcomingCafe} />
                            </Grid>
                            <Grid item xs={7} sx={{ paddingLeft: '6px' }}>
                                <Typography className="outletName">{outlet.cityName}</Typography>
                                <Typography className="outletCity">{outlet.stateName}</Typography>
                                <Typography className="outletAddress">Coming Soon...</Typography>
                            </Grid>
                        </Grid>
                    ))}
                </>
            )}

        </Box>
    )
}

export default DeliveryOutletScreen;
