import { Box, Button, TextField } from '@mui/material';
import React, { useCallback } from 'react'
import useRazorpay from "react-razorpay";

const CardScreen = () => {
  const [Razorpay] = useRazorpay();

    const handlePayment = useCallback(async () => {
        const options = {
            key: "rzp_test_CuEVwONWGPg8yo",
            amount: 2000,
            currency: "INR",
            name: "Xpanse",
            description: "Test Transaction",
            contact: '9113029491',
            email: 'amit@gmail.com',
            prefill: {
                method: "netbanking",
            },
            // card: {
            //     number: "4854980604708430",
            //     name: "AMit",
            //     expiry_month: "12",
            //     expiry_year: "25",
            //     cvv: "655"
            // }
        };
        if (Razorpay) {
            const rzpay = new Razorpay(options);
            rzpay.open(options);
        }
    }, [Razorpay]);

    return (
        <Box>
            <TextField
                variant="outlined"
                placeholder="Search"
            />
            <button onClick={handlePayment}>Heyyyy</button>
        </Box>
    )
}

export default CardScreen;