// store.js
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import loginReducer from './Login/loginSlice';
import productsReducer, { fetchProducts, fetchCategories } from './Products/productsSlice';
import userReducer from './Profile/UserSlice';
import cartReducer from './Cart/cartSlice';
// import storiesReducer, { fetchStories } from './Stories/storiesSlice';
import timeReducer from './Cart/timeSlice';
import addressReducer from './Address/addressSlice';
import { Search } from '@mui/icons-material';
import searchReducer from './Search/SearchSlice';

// Retrieve user data from localStorage
const userDataFromStorage = JSON.parse(localStorage.getItem('userData'));

const initialState = {
  login: {
    isLoggedIn: userDataFromStorage ? true : false,
    user: userDataFromStorage || null,
  },
};

const rootReducer = combineReducers({
  login: loginReducer,
  // products: productsReducer,
  user: userReducer,
  cart: cartReducer,
  time: timeReducer,
  address: addressReducer,
  search: searchReducer
});

const store = configureStore({
  reducer: rootReducer,
  preloadedState: initialState,
});

// store.dispatch(fetchCategories()).then((categoriesAction) => {
//   const categories = categoriesAction.payload || [];
//   const initialCategoryId = categories.length > 0 ? categories[0]._id : null;
//   store.dispatch(fetchProducts(initialCategoryId));
// });

export default store;