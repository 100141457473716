import { ShoppingCartCheckout, ShoppingCartOutlined } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import React from 'react';

function FloatingCart() {
  return (
    <Box className="floatingCart">
        <Box sx={{ display: 'flex'}}>
            <ShoppingCartOutlined />
            <Typography>
                1 Item
            </Typography>
        </Box>
        <Box>
            <Typography>₹350</Typography>
            <Typography>₹350</Typography>
        </Box>
    </Box>
  )
}

export default FloatingCart;
