import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Box, Chip, Menu, MenuItem } from '@mui/material';
import Images from '../utils/Images';
import { SearchBar } from './SearchBar';

const StickyHeader = ({ isVisible, transitionDuration }) => {

    return (
        <AppBar elevation={2} className="sticky-header" position="fixed" style={{
            transition: `opacity ${transitionDuration}ms`,
            opacity: isVisible ? 1 : 0,
            visibility: isVisible ? 'visible' : 'hidden'
        }}>
            <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box className="navbar-brand" sx={{ height: 'auto', display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                    <img className='navLogo' src={Images.xpanseNewNavLogo} alt="Logo" />
                </Box>
                <SearchBar />
            </Toolbar>
        </AppBar>
    );
};

export default StickyHeader;
