import React, { useEffect, useState } from 'react';
import { Box, Grid, Hidden, Typography } from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import axios from 'axios';
import { API } from '../../utils/Api';
import Images from '../../utils/Images';
import { KeyboardArrowLeft } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const AutoPlaySwipeableViews = SwipeableViews;

const MembershipScreen = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [allLevelRewards, setAllLevelRewards] = useState([]);
  const [activeRewardIndex, setActiveRewardIndex] = useState({});
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  useEffect(() => {
    const getMembershipLevels = async () => {
      try {
        const response = await axios.get(`${API.BASE_URL}level/getAllRewards`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setAllLevelRewards(response?.data?.data?.allLevelRewards);
        let initialActiveRewards = {};
        response?.data?.data?.allLevelRewards?.forEach((level, index) => {
          initialActiveRewards[index] = 0;
        });
        setActiveRewardIndex(initialActiveRewards);
        console.log('Success while fetching membership levels data:', response?.data?.data?.allLevelRewards);
      } catch (error) {
        console.error('Error while fetching membership levels data:', error);
      }
    };
    getMembershipLevels();
  }, [token]);

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const handleNext = () => {
    if (activeStep < allLevelRewards.length - 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const Dots = ({ count, activeIndex }) => (
    <Box sx={{ display: 'flex', justifyContent: 'center', p: 1 }}>
      {Array.from({ length: count }, (_, i) => (
        <Box
          key={i}
          sx={{
            width: 10,
            height: 10,
            borderRadius: '50%',
            backgroundColor: i === activeIndex ? '#49A6CF' : '#FFF',
            margin: '0 4px',
          }}
        />
      ))}
    </Box>
  );

  return (
    <Box className="membershipScreen">
      <Hidden mdUp>
        <KeyboardArrowLeft onClick={() => navigate('/profileScreen')} sx={{ color: '#fff', fontSize: '30px', padding: '10px 0 0 0' }} />
      </Hidden>
      <Box className="memberLevelBox">
        <img className="iconsForControl" onClick={handleBack} src={Images.prevButton} alt='Prev Button' style={{ visibility: activeStep > 0 ? 'visible' : 'hidden' }} />
        <AutoPlaySwipeableViews
          axis={'x'}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          {allLevelRewards?.map(level => (
            <Box key={level?._id} position="relative">
              <Typography variant="h6" sx={{ position: 'absolute', top: '15px', left: '10px', color: '#fff', padding: '5px' }} >
                {`${level?.title}`}
              </Typography>
              {level?.requiredPoints !== 0 && (
                <Typography variant="p" sx={{ position: 'absolute', top: '45px', left: '10px', color: '#fff', padding: '5px' }} >
                  {`Required points ${level?.requiredPoints}`}
                </Typography>
              )}
              <img src={level?.bannerImage} className="profile-img" alt={`Level ${level?.sequenceNo}`} />
            </Box>
            // <img key={level?._id} src={level?.bannerImage} className="profile-img" alt={`Level ${level?.sequenceNo}`} />
          ))}
        </AutoPlaySwipeableViews>
        <img className="iconsForControl" onClick={handleNext} src={Images.nextButton} alt='Next Button' style={{ visibility: activeStep < allLevelRewards.length - 1 ? 'visible' : 'hidden' }} />
      </Box>
      <Box className="secondBox">
        {allLevelRewards?.length > 0 && (
          <AutoPlaySwipeableViews
            axis={'x'}
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
          >
            {allLevelRewards.map((level, index) => (
              <Box key={level._id} className="memberRewardsBox">
                  <Typography className="memberTitle">{level.title}</Typography>
                  {level.levelRewards.map((reward, idx) => (
                      <Grid key={reward._id} container className="rewardCard">
                        <Grid item xs={3}>
                          <img className="rewardIcon" alt='Reward Icon' src={reward.icon || Images.defaultRewardIcon} />
                        </Grid>
                        <Grid item xs={9}>
                          <Typography className="title">{reward?.title}</Typography>
                          <Typography className="content">
                            {reward?.description}
                          </Typography>
                        </Grid>
                      </Grid>
                  ))}
                  {/* Consider implementing the Dots navigation here if needed */}
              </Box>
            ))}
          </AutoPlaySwipeableViews>
        )}
        <Dots count={allLevelRewards.length} activeIndex={activeStep} />
      </Box>
    </Box>
  );
}

export default MembershipScreen;
