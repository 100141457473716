import React from 'react';

const PrimaryButton = ({ title, onClick, disabled, spinner = true, disabledTitle, style, disabledColor = '#003049' }) => {
    const buttonClassName = `primary-button-container ${disabled ? 'disabled' : ''}`;
    const buttonStyle = {
        ...style,
        backgroundColor: disabled ? disabledColor : (style && style.backgroundColor),
    };

    return (
        <button
            onClick={disabled ? null : onClick}
            disabled={disabled}
            className={buttonClassName}
            style={buttonStyle}
        >
            {disabled && spinner && (
                <div className="spinner">
                    <div className="circle"></div>
                </div>
            )}

            <span className='title'>{disabled ? disabledTitle : title}</span>
        </button>
    );
};

export default PrimaryButton;