import React from 'react';
import Images from '../utils/Images';

const AddonIconComponent = ({ iconInfo, selected }) => {
  let iconSrc;

  switch (iconInfo.name) {
    case 'regular_drink':
      iconSrc = selected ? Images.drinkSmallSelected : Images.drinkSmall;
      break;
    case 'medium_drink':
      iconSrc = selected ? Images.drinkMediumSelected : Images.drinkMedium;
      break;
    case 'large_drink':
      iconSrc = selected ? Images.drinkLargeSelected : Images.drinkLarge;
      break;
    default:
      return null;
  }

  return <img className='addonSizeIcon' style={{ cursor: 'pointer' }} src={iconSrc} alt={`${iconInfo.name} icon`} />;
};

export default AddonIconComponent;
