import { AddCircleOutline, CheckBox, DeleteOutline, EditOffOutlined, Home, HomeRounded, KeyboardArrowLeft, LocationOnOutlined, ModeEditOutline, MyLocation, WorkRounded } from '@mui/icons-material';
import { Box, Typography, CircularProgress, Drawer, useMediaQuery, Dialog, DialogTitle, DialogContent, DialogContentText, Button, DialogActions, Grid, TextField, Hidden } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { API } from '../../../utils/Api';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import AddAddressMapScreen from '../DeliveryScreens/AddAddressMapScreen';
import { useTheme } from '@emotion/react';
import toast from 'react-hot-toast';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';

const SavedAddressesScreen = () => {
    const token = localStorage.getItem("token");
    const navigate = useNavigate();
    const [savedUserAddressList, setSavedUserAddressList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [addAddressMapScreen, setAddAddressMapScreen] = useState(false);
    const [addressToDelete, setAddressToDelete] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const theme = useTheme();
    const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
    const [addAddressModal, setAddAddressModal] = useState(false);
    const [activeChip, setActiveChip] = useState('HOME');
    const [showAddressNameField, setShowAddressNameField] = useState(false);
    const address = useSelector((state) => state.address);
    const [addressToEdit, setAddressToEdit] = useState(null);
    const selectedDeliveryAddress = localStorage.getItem("selectedAddress");
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [deliveryAddressId, setDeliveryAddressId] = useState('');
    
    const fetchUserAddresses= async () => {
        try {
            const response = await axios.get(`${API.BASE_URL}delivery-details/user`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            console.log('Saved user address', response?.data);
            setSavedUserAddressList(response?.data?.data?.deliveryDetails);
        } catch (error) {
            console.log('Saved user address Error', error?.response?.data);
        } finally {
            setLoading(false);
        }
    };

    const deleteAddress= async (addressId) => {
        try {
            const response = await axios.delete(`${API.BASE_URL}delivery-details`,{
                data: { "_id": addressId },
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            console.log('Address deleted successfully', response?.data);
            toast.success("Address deleted successfully");
            fetchUserAddresses();
        } catch (error) {
            console.log('Delete address Error', error?.response?.data);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUserAddresses();
        const selectedDeliveryAddress = localStorage.getItem("selectedAddress");
        if (selectedDeliveryAddress) {
            const address = JSON.parse(selectedDeliveryAddress);
            setDeliveryAddressId(address._id);
        }
    },[]);

    const handleSelectedAddressClick = (address) => {
        localStorage.setItem('selectedAddress', JSON.stringify(address));
        localStorage.setItem('selectedDeliveryAddressType', 'EXISTING');
        navigate('/productsListing');
        console.log('Selected address:', address);
    };

    const handleAddAddressButtonClick = () => {
        if (isMdUp) {
            setAddAddressMapScreen(true);
        } else {
            navigate('/addAddressMapScreen');
        }
    };

    const toggleAddAddressMapScreen = (open) => (event) => {
        setAddAddressMapScreen(open);
    };

    const handleDeleteClick = (addressId) => {
        setAddressToDelete(addressId);
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        setAddressToDelete(null);
    };

    const handleConfirmDelete = () => {
        if (addressToDelete) {
            deleteAddress(addressToDelete);
        }
        handleDialogClose();
    };

    const handleEditClick = (address) => {
        setAddressToEdit(address);
        setActiveChip(address.type);
        setShowAddressNameField(address.type === 'OTHER');
        setAddAddressModal(true);
        console.log(address);
    };

    const validationSchema = Yup.object().shape({
        flat: Yup.string().required('Flat / H. No. / Floor No. / Building Name is required').matches(/^[A-Za-z0-9][A-Za-z0-9 ,.'-]*$/, 'Cannot contain a special character').min(2, 'Must be at least 2 characters').trim(),
        street: Yup.string().required('Street Address is required').matches(/^[A-Za-z0-9][A-Za-z0-9 ,.'-]*$/, 'Cannot contain a special character').min(2, 'Must be at least 2 characters').trim(),
        receiverName: Yup.string().required('Name is required').matches(/^[A-Za-z][A-Za-z'-]* ?([A-Za-z'-]+ ?)*$/, 'Name must contain only letters, spaces, hyphens, and apostrophes').min(2, 'Name must be at least 2 characters').trim(),
        receiverContact: Yup.string().required("Receiver's Contact is required").matches(/^[6789][0-9]{9}$/, 'Must be a 10 digit number starting with 6, 7, 8, or 9').trim(),
        addressName: showAddressNameField ? Yup.string().required('Address Name is required').matches(/^[A-Za-z0-9][A-Za-z0-9 ,.'-]*$/, 'Cannot contain a special character').min(2, 'Must be at least 2 characters').trim() : Yup.string(),
    });

    const initialValues = {
        flat: addressToEdit ? addressToEdit.addressLine1 : '',
        street: addressToEdit ? addressToEdit.addressLine2 : '',
        landmark: addressToEdit ? addressToEdit.landmark : '',
        receiverName: addressToEdit ? addressToEdit.receiverName : '',
        receiverContact: addressToEdit ? addressToEdit.receiverContact : '',
        addressName: addressToEdit ? addressToEdit.addressName : ''
    };

    const handleChipClick = (chipName) => {
        setActiveChip(chipName);
        console.log(chipName);
        if (chipName === 'OTHER') {
            setShowAddressNameField(true);
        } else {
            setShowAddressNameField(false);
        }
    };

    const handleSubmit = async (values, { setSubmitting }) => {
        console.log('Submitted values:', values);
        setSubmitting(false);
        try {
            const requestBody = {
                _id: addressToEdit?._id,
                type: activeChip,
                addressLine1: values.flat,
                addressLine2: values.street,
                // landmark: values.landmark,
                receiverName: values.receiverName,
                receiverContact: values.receiverContact,
                pincode: addressToEdit?.pincode,
                city: addressToEdit?.city,
                state: addressToEdit?.state,
                country: "India",
                coordinates: {                     
                    latitude: addressToEdit?.coordinates?.latitude,
                    longitude: addressToEdit?.coordinates?.longitude
                }
            };
            if (values.landmark) {
                requestBody.landmark = values.landmark;
            }
            if (activeChip === "OTHER") {
                requestBody.addressName = values.addressName;
            }
            const response = await axios.put(`${API.BASE_URL}delivery-details`, requestBody, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            console.log('Address updated successfully:', response.data.data.dbResponse);
            handleAddAddressModalClose();
            toast.success("Address updated Successfully");
            fetchUserAddresses();
        } catch (error) {
            console.error('Error updating address:', error);
            toast.error(error.response.data.error);
        }
    };

    const handleAddAddressModalOpen = () => {
        setAddAddressModal(true);
    };

    const handleAddAddressModalClose = () => {
        setAddAddressModal(false);
    };

    return (
        <>
        <Box className="savedAddressesScreen">
            <Box sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center', color: '#186B8C', fontSize: '21px', fontWeight: '600', paddingLeft: '10px' }}>
                <Hidden mdUp>
                    <KeyboardArrowLeft  onClick={() => navigate(-1)}/>
                </Hidden>
                Saved Addresses
            </Box>
            <Box onClick={handleAddAddressButtonClick} className="addAddressButton">
                <AddCircleOutline sx={{ paddingRight: '5px' }} />
                Add New Address
            </Box>
            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: '30px', height: '100vh' }}>
                    <CircularProgress />
                </Box>
            ) : (
                savedUserAddressList.length > 0 ? (
                    savedUserAddressList.map((address, index) => {
                    const isDisabled = address._id === deliveryAddressId;
                    return (
                        <Box key={address._id} className={`addressBox ${isDisabled ? 'disabledAddress' : ''}`} sx={{ borderBottom: (savedUserAddressList.length === 1 || index === savedUserAddressList.length - 1) ? 'none' : '1px solid #c5c5c5', margin: '10px 20px 0px 20px' }}>
                            <Box sx={{ display: 'flex', padding: '0 0 0 0px' }}>
                                {address.type === 'HOME' && <HomeRounded sx={{ color: '#1E9CED' }} />}
                                {address.type === 'WORK' && <WorkRounded sx={{ color: '#1E9CED' }} />}
                                {address.type !== 'HOME' && address.type !== 'WORK' && <LocationOnOutlined sx={{ color: '#1E9CED' }} />}
                                <Typography sx={{ fontWeight: '700', padding: '0 0 0 10px' }}>{address.receiverName}</Typography>
                            </Box>
                            <Typography sx={{ padding: '0 10px 15px 30px' }}>{address?.addressLine1}, {address?.addressLine2}, {address?.landmark}, {address?.state}, {address?.pincode}</Typography>
                            <Box className="addressEditBox" sx={{ display: 'flex', justifyContent: 'space-between', borderTop: '1px solid #1E9CED', paddingTop: '10px' }}>
                                <Box>
                                    <DeleteOutline onClick={!isDisabled ? (e) => {
                                        e.stopPropagation();
                                        handleDeleteClick(address._id);
                                    } : null}
                                        sx={{ color: isDisabled ? 'grey' : '#EF5555', borderRight: '1px solid #1E9CED', paddingRight: '5px', cursor: isDisabled ? 'not-allowed' : 'pointer' }} 
                                    />
                                    <ModeEditOutline onClick={!isDisabled ? () => handleEditClick(address) : null} sx={{ color: isDisabled ? 'grey' : '#1E9CED', paddingLeft: '5px', cursor: isDisabled ? 'not-allowed' : 'pointer' }} />
                                </Box>
                                { isDisabled && (
                                     <Box sx={{ display: 'flex', alignItems: 'center', fontSize: '12px', fontWeight: '600', color: '#1E9CED' }}>
                                        Selected address 
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    ); })
                ) : (
                    <Box sx={{ display: 'flex', textAlign: 'center', padding: '30px 15px 0 15px', height: '100vh', flexDirection: 'column', color: '#0E4961' }}>
                        <Typography variant="h6">No saved addresses found.</Typography>
                        <Typography variant="body1">Click on "Add New Address" to add your first address.</Typography>
                    </Box>
                )
            )}
        </Box>
        <Drawer
            anchor="right"
            open={addAddressMapScreen}
            onClose={toggleAddAddressMapScreen(false)}
            classes={{ paper: "drawerPaperForEditProfile" }}
        >
            <Box onClick={(event) => event.stopPropagation()}   sx={{ }}>
                <AddAddressMapScreen />
            </Box>
        </Drawer>
        <Dialog
            open={dialogOpen}
            onClose={handleDialogClose}
        >
            <DialogTitle>Confirm Address Deletion</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to delete this address?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDialogClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleConfirmDelete} color="primary">
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
        <Dialog open={addAddressModal} onClose={handleAddAddressModalClose} className="addModalAddress"
            sx={{ 
                '& .MuiPaper-root': { 
                borderRadius: '16px',
                '&::-webkit-scrollbar': {
                    display: 'none'
                }
            } 
        }}>
            <Box className="addressModal">
                <Grid container className="address-container">
                    <Grid item xs={9}>
                        <Typography className="address">
                            <MyLocation sx={{ paddingRight: '5px' }} />
                            {addressToEdit?.city}
                        </Typography>
                        <Typography className="address2">
                            {addressToEdit?.state}, {addressToEdit?.pincode}.
                        </Typography>
                    </Grid>
                </Grid>
                <Box>
                    <Typography className="enterDetailsTitle">Enter Address Details</Typography>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                        >
                        {({ values, errors, touched, handleChange, handleBlur, isSubmitting }) => (
                            <Form className="formBox">
                                <TextField
                                    name="flat"
                                    className="textField"
                                    fullWidth
                                    placeholder="Enter"
                                    label="Flat / H. No. / Floor No. / Building Name"
                                    value={values.flat}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {errors.flat && touched.flat && <div className="errorText">{errors.flat}</div>}
                                <TextField
                                    name="street"
                                    className="textField"
                                    fullWidth
                                    placeholder="Enter"
                                    label="Street Address"
                                    value={values.street}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {errors.street && touched.street && <div className="errorText">{errors.street}</div>}
                                <TextField
                                    name="landmark"
                                    className="textField"
                                    fullWidth
                                    placeholder="Enter"
                                    label="Landmark (Optional)"
                                    value={values.landmark}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                <TextField
                                    name="receiverName"
                                    className="textField"
                                    fullWidth
                                    placeholder="Enter"
                                    label="Receiver's Name"
                                    value={values.receiverName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {errors.receiverName && touched.receiverName && <div className="errorText">{errors.receiverName}</div>}
                                <TextField
                                    name="receiverContact"
                                    className="textField"
                                    fullWidth
                                    placeholder="Enter"
                                    label="Receiver's Contact"
                                    value={values.receiverContact}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {errors.receiverContact && touched.receiverContact && <div className="errorText">{errors.receiverContact}</div>}
                                <Grid container justifyContent="space-around">
                                    <Grid item xs={3}>
                                        <Box
                                            className={`addressChip ${activeChip === 'HOME' ? 'selected' : ''}`}
                                            onClick={() => handleChipClick('HOME')}
                                        >
                                            <Home/> Home
                                        </Box>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Box
                                            className={`addressChip ${activeChip === 'WORK' ? 'selected' : ''}`}
                                            onClick={() => handleChipClick('WORK')}
                                        >
                                            <WorkRounded/> Work
                                        </Box>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Box
                                            className={`addressChip ${activeChip === 'OTHER' ? 'selected' : ''}`}
                                            onClick={() => handleChipClick('OTHER')}
                                        >
                                            <LocationOnOutlined/> Other
                                        </Box>
                                    </Grid>
                                </Grid>
                                {showAddressNameField && (
                                    <>
                                    <TextField
                                        name="addressName"
                                        className="textField"
                                        fullWidth
                                        placeholder="Enter"
                                        label="Address Name"
                                        value={values.addressName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {errors.addressName && touched.addressName && <div className="errorText">{errors.addressName}</div>}
                                    </>
                                )}
                                <Button type="submit" className="button" disabled={isSubmitting}>
                                    {isSubmitting ? 'Submitting...' : 'Continue'}
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </Box>
        </Dialog>
        </>
    )
}

export default SavedAddressesScreen;
