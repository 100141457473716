import { Box, Button, ClickAwayListener, Dialog, DialogContent, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react'
import PickUpCartComponent from '../../components/PickUpCartComponent';
import Images from '../../utils/Images';
import QRCode from 'qrcode.react';
import ReactDOMServer from 'react-dom/server';
import { QRCodeSVG } from 'qrcode.react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Player } from '@lottiefiles/react-lottie-player';
import { useDispatch } from 'react-redux';
import { InfoOutlined, KeyboardArrowRight } from '@mui/icons-material';
import axios from 'axios';
import { API } from '../../utils/Api';

const OrderDetails = () => {
    const [qrCodeSvg, setQrCodeSvg] = useState(null);
    const token = localStorage.getItem('token');
    const navigate = useNavigate();
    const location = useLocation();
    const screenLocation = location?.state?.screenLocation;
    // const orderDetails = location?.state?.orderDetails;
    const [orderDetails, setOrderDetails] = useState(location?.state?.orderDetails);
    const [orderText, setOrderText] = useState("Placed");
    const [countdown, setCountdown] = useState(30);
    const [showBox, setShowBox] = useState(true); 
    const dispatch = useDispatch();
    const [orderCancelModal, setOrderCancelModal] = useState(false);
    const [levelUpgradeModal, setLevelUpgradeModal] = useState(false);
    const [allRewards, setAllRewards] = useState([]);
    const [openTaxTooltip, setTaxOpenTooltip] = useState(false);
    const [outletData, setOutletData] = useState(JSON.parse(localStorage.getItem('selectedStoreData')));

    // console.log('order details testing', orderDetails.order._id);

    useEffect(() => {
        const handleBackButtonClick = (event) => {
            event.preventDefault();  // Prevent the default back button behavior
            navigate('/advertiseScreen');  // Navigate to the OrderHistoryScreen
        };
        window.addEventListener('popstate', handleBackButtonClick);
        return () => {
            window.removeEventListener('popstate', handleBackButtonClick);  // Clean up the event listener
        };
    }, [navigate]);

    useEffect(() => {
        if (orderDetails?.order?.orderStatus === 'CANCELLED') {
            setOrderText('Cancelled');
        }
    }, [orderDetails?.order?.orderStatus]);

    useEffect(() => {
        const timerId = setInterval(() => {
            setCountdown(currentCount => {
                if (currentCount <= 1) {
                    clearInterval(timerId);
                    setShowBox(false); 
                    return 0;
                }
                return currentCount - 1;
            });
        }, 1000);
        return () => clearInterval(timerId);
    }, [screenLocation]); 

    useEffect(() => {
        // dispatch(orderDetails.order.readyTime);
        // console.log(location?.state?.orderDetails.order.readyTime);
        window.scrollTo(0, 0);
        // updateOrderStatus();
    }, []);

    const updateOrderStatus = async () => {
        try {
            const response = await axios.get(`${API.BASE_URL}order/getConsumerOrders/${outletData._id}?_id=${orderDetails.order._id}`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setOrderDetails(response?.data?.data?.orders[0]);
        } catch (error) {
            console.error('Failed to fetch order:', error);
        }
    };

    useEffect(() => {
        const intervalId = setInterval(updateOrderStatus, 3000);
        return () => clearInterval(intervalId);
    }, []);

    const handleCancelOrder = async () => {
        try {
            const response = await axios.put(`${API.BASE_URL}order/cancel`, {
                "_id": orderDetails.order._id
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            // console.log('Order cancelled:', response.data);
            setShowBox(false);
            setOrderText("Cancelled");
            setOrderCancelModal(true);
        } catch (error) {
            console.error('Failed to cancel order:', error);
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const dayOfWeek = days[date.getUTCDay()];
        const month = months[date.getUTCMonth()];
        const year = date.getUTCFullYear();
        const day = date.getUTCDate();
        return `${dayOfWeek}, ${day}- ${month} - ${year}`;
    };

    const totalSavings = useMemo(() => {
        return orderDetails.orderItems?.reduce((acc, item) => {
            const savingsPerItem = (item?.product?.mrp - item?.product?.offerPrice) * item?.quantity;
            const savingsPerAddons = item?.addons?.reduce((addonAcc, addon) => {
                return addonAcc + ((addon?.mrp - addon?.offerPrice) * (item?.quantity || 0));
            }, 0);
            return acc + savingsPerAddons + savingsPerItem;
        }, 0);
    }, [orderDetails?.orderItems]);

    const totalCouponDiscount = orderDetails.orderItems?.reduce((total, item) => {
        return total + (item?.discount);
    }, 0);

    const totalOfferPrice = useMemo(() => {
        return orderDetails?.orderItems?.reduce((acc, item) => {
            const totalOfferPrice = (item?.product?.offerPrice) * item?.quantity;
            return acc + totalOfferPrice;
        }, 0);
    }, [orderDetails?.orderItems]);

    const totalSizeUpgradeAmount = useMemo(() => {
        return orderDetails.orderItems?.reduce((acc, item) => {
            return acc + (item?.sizeUpgradePayload?.newAddonItemOfferPrice || 0) - (item?.sizeUpgradePayload?.prevAddonItemOfferPrice || 0);
        }, 0);
    }, [orderDetails.orderItems]);

    const totalTaxAmount = (orderDetails?.order?.totalTaxAmount || 0) + (orderDetails?.order?.GSTPackingCharges || 0) +  (orderDetails?.order?.GSTPlatformFees || 0);
    const totalSum = totalTaxAmount + totalOfferPrice;
    const formattedSum = totalSum.toFixed(2).replace(/\.?0*$/, '');
    const totalQuantityInOrder = orderDetails?.orderItems?.reduce((total, item) => { return total + item?.quantity; }, 0);
   
    const handleTaxTooltipOpen = () => {
        setTaxOpenTooltip(true);
    };

    const handleTaxTooltipClose = () => {
        setTaxOpenTooltip(false);
    };

    const taxTooltipContent = (
        <Box>
            <Typography variant="body2" padding={'2px 0 2px 0'} sx={{ display: 'flex', justifyContent: 'space-between'}} component="p">
                Base Tax: <span>₹{orderDetails?.order?.totalTaxAmount}</span>
            </Typography>
            {orderDetails?.order?.ordersType === 'DELIVERY' && (
                <Typography variant="body2" padding={'2px 0 2px 0'} sx={{ display: 'flex', justifyContent: 'space-between'}} component="p">
                    GST Packing Charges: <span>₹{orderDetails?.order?.GSTPackingCharges}</span>
                </Typography>
            )}
            <Typography variant="body2" padding={'2px 0 2px 0'} sx={{ display: 'flex', justifyContent: 'space-between'}} component="p">
                GST Platform Fees: <span>₹{orderDetails?.order?.GSTPlatformFees}</span>
            </Typography>
            <Typography variant="body2" padding={'2px 0 2px 0'} component="p" sx={{ borderTop: '1px solid #fff', display: 'flex', justifyContent: 'space-between' }}>
                Total Tax: <span>₹ {totalTaxAmount?.toFixed(2)}</span>
            </Typography>
        </Box>
    );

    const getAllRewardsInfo = async () => {
        try {
            const response = await axios.get(`${API.BASE_URL}level/getAllRewards`,
                {
                    headers: {
                    Authorization: `Bearer ${token}`
                    }
                }
            )
            console.log('membership rewards', response.data);
            console.log('Level upgraded to', orderDetails?.levelUpgradedTo);
            if (orderDetails?.levelUpgradedTo !== null) {
                setLevelUpgradeModal(true);
                const FilteredReward = response?.data?.data?.allLevelRewards?.filter(item => item?._id === orderDetails?.levelUpgradedTo?.levelId);
                setAllRewards(FilteredReward);
                console.log('uyfghfg hggcvhgg hchgghgg hgchgc ggchg', FilteredReward);
            }
        } catch (error) {
            console.log(error, 'error in order Sucess');
        }
    }
    
    useEffect(() => {
        if (screenLocation === "PAYMENT") {
            getAllRewardsInfo();
        }
    }, [screenLocation]);

    const calculateTotalPrice = (item) => {
        let totalPrice = item?.product?.offerPrice * item?.quantity;
        item?.addons?.forEach(addon => {
        totalPrice += addon?.offerPrice * item?.quantity;
        });
        return totalPrice?.toFixed(2).replace(/\.?0*$/, '');
    };

    const totalMrpOrder = useMemo(() => {
        return orderDetails?.orderItems?.reduce((acc, item) => {
            const productMrp = (item?.product?.mrp || 0) * (item?.quantity || 0);
            const addonsMrp = item?.addons?.reduce((addonAcc, addon) => {
                return addonAcc + ((addon?.mrp || 0) * (item?.quantity || 0));
            }, 0);
            return acc + productMrp + addonsMrp;
        }, 0);
    }, [orderDetails?.orderItems]);

    const totalOfferPriceOrder = useMemo(() => {
        return orderDetails?.orderItems?.reduce((acc, item) => {
            const productOfferPrice = (item?.product?.offerPrice || 0) * (item?.quantity || 0);
            const addonsOfferPrice = item?.addons?.reduce((addonAcc, addon) => {
                return addonAcc + ((addon?.offerPrice || 0) * (item?.quantity || 0));
            }, 0);
            return acc + productOfferPrice + addonsOfferPrice;
        }, 0);
    }, [orderDetails?.orderItems]);

    const readyTime = orderDetails?.order?.readyTime;

    const calculateRemainingTime = (readyTime) => {
        const currentTime = new Date();
        const [hours, minutes] = readyTime.split(':').map(Number);
        const readyTimeDate = new Date(currentTime.getFullYear(), currentTime.getMonth(), currentTime.getDate(), hours, minutes);
        const diff = readyTimeDate - currentTime; // Difference in milliseconds
        const remainingMinutes = Math.round(diff / 60000); // Convert milliseconds to minutes
        return remainingMinutes > 0 ? remainingMinutes : 0; // Ensure it doesn't go negative
    };

    const remainingMinutes = calculateRemainingTime(readyTime);

    return (
        <Box className="orderDetails">
            <PickUpCartComponent />
            {screenLocation === "PAYMENT" && showBox && orderDetails?.order?.orderStatus !== "DELIVERED" && orderDetails?.order?.orderStatus !== "READY-TO-PICK" &&
                <Box sx={{ backgroundColor: '#71BAE6', padding: '20px', color: '#fff', fontSize: '20px', textAlign: 'center', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                    Order to cancel {countdown}s
                    <Button 
                        onClick={handleCancelOrder} 
                        sx={{ color: 'white', backgroundColor: 'red' }}
                    >
                        Cancel Order
                    </Button>
                </Box>
            }
            {orderDetails?.order?.orderStatus !== "DELIVERED" && orderDetails?.order?.orderStatus !== "CANCELLED" && (
                <>
                    {/* <Typography className="title">Your Order Has Been {orderText} <br/>Successfully!</Typography> */}
                    <Typography className="title">Your Order Status: {orderDetails?.order?.orderStatus}</Typography>
                    <Typography className="title">Your Ready Time: {remainingMinutes} minutes</Typography>
                    <Box className="qrBox">
                        {/* <Player autoplay loop={true} keepLastFrame={true} src={Images.successGif} className="successGif" />*/}
                        <img className="successTick" src={Images.successTick} />
                        <Typography className="orderId">Order ID</Typography>
                        <Typography className="orderNo">#{orderDetails?.order?.order_sequence}{orderDetails?.order?.order_no}</Typography>
                        <QRCodeSVG className="qrCode" value={orderDetails?.order?.scanOrderId} />
                        <Typography className="scanText">Please Scan The QR Code At Store</Typography>
                    </Box>
                </>
            )}
            {orderDetails?.order?.orderStatus === "DELIVERED" && orderDetails?.order?.orderStatus !== "CANCELLED" && (
                <Box sx={{ textAlign: 'center', padding: '18px 0 0 0' }}>
                    <Typography variant='h5'>#{orderDetails?.order?.order_sequence}{orderDetails?.order?.order_no}</Typography>
                </Box>
            )}
            <Box className="orderFinalSummary">
                <img className="couponBorder" src={Images.couponBorder} />
                <Typography className="titleSecond">Item Summary</Typography>
                <Box sx={{ padding: '0 20px 0 20px'}}>
                    {orderDetails?.orderItems?.map((item, index) => (
                        <Grid key={item?._id} container className="item" sx={{ borderBottom: index === orderDetails?.orderItems?.length - 1 ? 'none' : '1px solid #C5C5C5' }}>
                            <Grid item className="detail-grid" xs={6}>
                                <Typography className="item-name">{item?.product?.name}</Typography>
                            </Grid>
                            <Grid item className="detail-grid" xs={3} sx={{ display: 'flex', flexDirection: 'column', textAlign: 'right' }}>
                                <Typography className="item-qty">Qty - {item?.quantity}</Typography>
                            </Grid>
                            <Grid item className="detail-grid" xs={3} sx={{ display: 'flex', flexDirection: 'column', textAlign: 'right' }}>
                                <Typography className="item-cost">₹{calculateTotalPrice(item)}</Typography>
                            </Grid>
                            <Grid item xs={12} sx={{ display: 'flex' }}>
                                {item?.addons?.length > 0 && (
                                    <>
                                        {item.addons.map((addon, index) => (
                                            <Typography key={index} sx={{ paddingRight: '3px'}} className="item-attribute">
                                                {addon.selectedValue}{index === item.addons.length - 1 ? '.' : ','}
                                            </Typography>
                                        ))}
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    ))}
                </Box>
            </Box>
            <Box className="orderSummarySecondBox">
                <Box className="orderSummaryTable">
                    <Typography className="head">Order Summary</Typography>
                    <Box className="row">
                        <Typography className="itemHead">Item(s)</Typography>
                        <Typography className="itemValue">{totalQuantityInOrder}</Typography>
                    </Box>
                    <Box className="row">
                        <Typography className="itemHead">Order Total</Typography>
                        <Typography className="itemValue"><span style={{ fontSize: '14px', textDecoration: 'line-through', color: 'gray' }}>₹{totalMrpOrder}</span> ₹{totalOfferPriceOrder}</Typography>
                    </Box>  
                    <Box className="row">
                        <Typography className="itemHead" sx={{ display: 'flex', alignItems: 'center'}}>Tax
                            <ClickAwayListener ClickAwayListener onClickAway={handleTaxTooltipClose}>
                            <Tooltip
                                PopperProps={{ disablePortal: true, }}
                                onClose={handleTaxTooltipClose}
                                open={openTaxTooltip}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                title={taxTooltipContent}
                            >
                                <IconButton onClick={handleTaxTooltipOpen} size="small" sx={{ padding: 0, marginLeft: '5px' }}>
                                    <InfoOutlined sx={{ fontSize: '12px' }} />
                                </IconButton>
                            </Tooltip>
                            </ClickAwayListener>
                        </Typography>
                        <Typography className="itemValue">₹ {totalTaxAmount?.toFixed(2)}</Typography>
                    </Box>
                    <Box className="row">
                        <Typography className="itemHead">Platform Fees</Typography>
                        <Typography className="itemValue">{orderDetails?.order?.platformFees?.toFixed(2) === 0 ? "Free" : `₹ ${orderDetails?.order?.platformFees?.toFixed(2)}`}</Typography>
                    </Box>
                    {totalCouponDiscount > 0 && (
                        <Box className="row">
                            <Typography className="itemHead">Coupon Discount</Typography>
                            <Typography className="itemValue" sx={{ color: 'green' }}>- ₹{totalCouponDiscount?.toFixed(2).replace(/\.?0*$/, '')}</Typography>
                        </Box>
                    )}
                    {/* {totalSizeUpgradeAmount > 0 &&
                        <Box className="row">
                        <Typography className="itemHead">Coupon Discount</Typography>
                        <Typography className="itemValue" sx={{ color: 'green' }}>- ₹{totalSizeUpgradeAmount?.toFixed(2).replace(/\.?0*$/, '')}</Typography>
                        </Box>
                    } */}
                    {orderDetails?.order?.coupon && totalSizeUpgradeAmount <= 0 && ( <Typography className="itemHead" sx={{ paddingBottom: '4px', color: '#1E9CED'}}>( {orderDetails?.order?.coupon?.couponCode} )</Typography>)}
                    <Box className="row" sx={{ borderTop: '1px dashed #707070', borderBottom: '1px solid #707070'}}>
                        <Typography className="itemHead" sx={{ fontWeight: '600'}}>Total Payable</Typography>
                        <Typography className="itemValue" sx={{ fontWeight: '600'}}>₹ {orderDetails?.order?.grandTotal.toFixed(2)}</Typography>
                    </Box>
                </Box>
                <Box className="savedBox">
                    <Typography>You Saved</Typography>
                    <Typography>₹ {((parseFloat(totalSavings || 0) + parseFloat(orderDetails?.order?.totalDiscount || 0)).toFixed(2).replace(/\.?0*$/, ''))}</Typography>
                    {/* <Typography>₹ {orderDetails?.order?.totalDiscount}</Typography> */}
                </Box>
                <Box className="paymentBox">
                    <Typography className="head">Payment Details</Typography>
                    <Box className="payDetails">
                        <Typography>Payment mode: {orderDetails?.order?.paymentMode}</Typography>
                        <Typography>Date: {formatDate(orderDetails?.order?.createdAt)}</Typography>
                    </Box>
                    <Typography onClick={() => navigate('/productsListing')} className="button">Continue Shopping<KeyboardArrowRight /></Typography>
                </Box>
            </Box>
            <Dialog open={orderCancelModal} onClose={() => { setOrderCancelModal(false); navigate('/productsListing'); }} sx={{ '& .MuiPaper-root': {  borderRadius: '16px', overflow: 'hidden', },}}>
                <Box className="addressModal">
                <Grid container className="address-container" sx={{ display: 'flex', justifyContent: 'center'}}>
                    <img src={Images.successTick} />
                    <h2>Your order was cancelled</h2>
                    <Button onClick={() => { setOrderCancelModal(false); navigate('/productsListing'); }}>Continue Shopping</Button>
                </Grid>
                </Box>
            </Dialog>
            <Dialog open={levelUpgradeModal} onClose={() => { setLevelUpgradeModal(false); }} sx={{ '& .MuiPaper-root': {  borderRadius: '16px', overflow: 'hidden', },}}>
                <DialogContent sx={{ bgcolor: '#fff', overflow: 'auto', borderRadius: 2 }}>
                    <div style={{ textAlign: 'center', padding: 2 }}>
                        <img src={Images.successTick} />
                        {allRewards?.length > 0 && (
                        <Typography variant="h6" style={{ fontWeight: 'bold', color: 'primary.contrastText' }}>
                            You have Reached {allRewards[0].title}
                        </Typography>
                        )}
                    </div>
                    {allRewards?.map((rewardsValues, index) => (
                        <div key={index} style={{ marginTop: 2 }}>
                        <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                            {rewardsValues.title} Benefits
                        </Typography>
                        {rewardsValues.levelRewards?.map((level, index) => (
                            <div key={index} style={{ display: 'flex', flexDirection: 'row', marginTop: 1 }}>
                                <Typography variant="body2" style={{ marginRight: 1 }}>*</Typography>
                                <div>
                                    <Typography variant="body2">{level.title}</Typography>
                                    <Typography variant="body2" style={{ color: 'grey.500' }}>
                                        {level.description}
                                    </Typography>
                                </div>
                            </div>
                        ))}
                        </div>
                    ))}
                    <Button onClick={() => { setLevelUpgradeModal(false); }}>Close</Button>
                </DialogContent>
            </Dialog>
        </Box>
    )
}

export default OrderDetails;
