import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import Images from '../utils/Images';

const SortByComponent = ({ activeFilter, onFilterChange }) => {

    const filters = [
        { name: 'All', value: null, icon: null },
        { name: 'Vegan', value: 'VEGAN', icon: Images.vegan },
        { name: 'Veg', value: 'VEG', icon: Images.veg },
        { name: 'Non Veg', value: 'NON-VEG', icon: Images.nonVeg },
    ];

    return (
        <Box className="sortByComponent">
        <Box sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
            <Typography className="sortBy">Sort By:</Typography>
            <Box className="filters">
            {filters.map((filter) => (
                <Typography
                key={filter.name}
                className={`filterName ${activeFilter === filter.value ? 'filterActive' : ''}`}
                onClick={() => onFilterChange(filter.value)}
                sx={{ cursor: 'pointer' }}
                >
                {filter.icon && <img className="icons" src={filter.icon} alt={filter.name} />}
                {filter.name}
                </Typography>
            ))}
            </Box>
        </Box>
        </Box>
    );
};

export default SortByComponent;
