import React from 'react'

const HomeScreen = () => {
  return (
    <div>
      <h1>Home Screen</h1>
      <h1>hgdfgsdgvsdfg</h1>
    </div>
  )
}

export default HomeScreen;
