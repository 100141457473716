import { ArrowBack, ArrowLeft, ThumbUpSharp, ThumbsUpDownRounded } from '@mui/icons-material';
import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import Images from '../../utils/Images';

const CouponScreen = () => {
  const couponText = "Flat 50% Off on your first order";
  const subText = "up ₹100 off on orders above ₹150"
  const code = "WELC50";

  return (
    <Box
      className="ReviewScreen"
      sx={{
        position: 'relative',
        backgroundColor: '#6AC5FD',
        height: '100vh',
        margin: '0',
        padding: '15px',
        overflow: 'hidden',
      }}
    >
      <img src={Images.coupon} alt="Coupon" style={{ width: '100%', height: 'auto' }} />
      <Typography
        variant="body1"
        sx={{
          position: 'absolute',
          top: '50%',
          left: '30%',
          textTransform: 'uppercase',
          transform: 'translate(-20%, -65%) rotate(-3.8deg)',
          color: '#000',
          textAlign: 'left',
          fontSize: '30px',
          fontWeight: '600'
        }}
      >
        {couponText}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          position: 'absolute',
          top: '54%',
          left: '15%',
          textTransform: 'uppercase',
          transform: 'translate(-2%, -5%) rotate(-3.8deg)',
          color: '#5E5E5E',
          textAlign: 'left',
          fontSize: '18px',
          fontWeight: '600'
        }}
      >
        {subText}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          position: 'absolute',
          top: '59.5%',
          left: '15%',
          textTransform: 'uppercase',
          transform: 'translate(-2%, -5%) rotate(-3.8deg)',
          color: '#28A6F3',
          textAlign: 'left',
          fontSize: '18px',
          fontWeight: '600'
        }}
      >
        <span style={{ color: '#5E5E5E'}}>USE : </span> {code}
      </Typography>
    </Box>
  );
};

export default CouponScreen;